import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Form, Input, Row, Col, Spin, Divider, Button, Popconfirm, Icon, message } from 'antd';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-apollo';
import settingQuery from '../graphql/queries/settingQuery.graphql';
import saveMealsMutation from '../graphql/mutations/saveMealsMutation.graphql';
import saveSupplementsMutation from '../graphql/mutations/saveSupplementsMutation.graphql';

const { Search } = Input;

const AddNewItemComponent = (props) => {
  const [input, setInput] = useState('');
  const { settingTypeToSingular, addItem, searchIsActive } = props;
  return (
    <div>
      <h3>Create new {settingTypeToSingular}</h3>
      <Row>
        <Col span={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
          <Input
            value={input}
            disabled={searchIsActive}
            onChange={(e) => setInput(e.target.value)}
            placeholder={`Enter new ${settingTypeToSingular}`}
            style={{ flex: 10 }}
          />
          <Button
            type="primary"
            style={{
              flex: 1,
              left: -5,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            icon="plus"
            onClick={() => {
              addItem(input);
              setInput('');
            }}
            disabled={searchIsActive}
          >
            Add {settingTypeToSingular}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const SearchComponent = (props) => {
  const [input, setInput] = useState('');
  const { setFilteredSettingsKeys, settings, settingType, searchIsActive, setSearchIsActive } = props;

  return (
    <Row>
      <Col span={12} style={{ display: 'flex', marginRight: 24 }}>
        <Search
          placeholder={`Search ${settingType}`}
          onSearch={(value) => {
            setFilteredSettingsKeys(
              settings
                .filter((entry) => entry.value.toLowerCase().includes(value.toLowerCase()))
                .map((entry) => entry.key),
            );
            setSearchIsActive(true);
          }}
          onChange={(e) => setInput(e.target.value)}
          value={input}
          enterButton
          disabled={searchIsActive}
          style={{ flex: 7, marginRight: 20 }}
        />
        <Button
          type="primary"
          style={{ marginTop: -1, flex: 2, left: -5 }}
          onClick={() => {
            setFilteredSettingsKeys([]);
            setSearchIsActive(false);
            setInput('');
          }}
        >
          Reset search
        </Button>
      </Col>
    </Row>
  );
};

const SettingsItemComponent = (props) => {
  const { settings, changeItem, removeItem, settingTypeToSingular } = props;
  const [inputValue, setInputValue] = useState(settings.value);
  return (
    <Col span={6}>
      <Form.Item>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={(e) => changeItem(settings.key, e.target.value)}
          style={{ width: '80%' }}
        />
        <Popconfirm
          title={`Delete ${settingTypeToSingular}. Are you sure?`}
          onConfirm={() => {
            removeItem(settings.key);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Icon className="dynamic-delete-button" type="close-circle" style={{ fontSize: 18, marginLeft: 5 }} />
        </Popconfirm>
      </Form.Item>
    </Col>
  );
};

const SettingsComponent = (props) => {
  const { settingType, resetSettings } = props;

  const [submitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState([]);
  const [filteredSettingsKeys, setFilteredSettingsKeys] = useState([]);
  const [saveMeals] = useMutation(saveMealsMutation);
  const [saveSupplements] = useMutation(saveSupplementsMutation);
  const [searchIsActive, setSearchIsActive] = useState(false);

  const { loading, error, data } = useQuery(settingQuery, {
    variables: { name: settingType },
    onCompleted: ({ setting }) => {
      const newSettings = [];
      for (let i = 0; i < setting.arrayStringValue.length; i++) {
        newSettings.push({
          key: i,
          value: setting.arrayStringValue[i],
        });
      }

      setSettings(newSettings);
    },
  });

  if (loading) return <Spin />;
  if (error) return `Error ${error.message}`;

  const { setting } = data;
  const { id, name } = setting;

  const saveItems = () => {
    setSubmitting(true);
    const valuesToSubmit = settings.filter((entry) => !!entry.value).map((entry) => entry.value);
    if (settingType === 'meals') {
      saveMeals({
        variables: {
          meals: valuesToSubmit,
        },
        update: (cache) => {
          cache.writeQuery({
            query: settingQuery,
            variables: { name: settingType },
            data: { setting: { id, name, arrayStringValue: valuesToSubmit, __typename: 'Setting' } },
          });
        },
      })
        .then(() => {
          setSubmitting(false);
          resetSettings();
          message.success('Meals saved!');
        })
        .catch((mealsError) => {
          setSubmitting(false);
          message.error(mealsError.message);
        });
    } else {
      saveSupplements({
        variables: {
          supplements: valuesToSubmit,
        },
        update: (cache) => {
          cache.writeQuery({
            query: settingQuery,
            variables: { name: settingType },
            data: { setting: { id, name, arrayStringValue: valuesToSubmit, __typename: 'Setting' } },
          });
        },
      })
        .then(() => {
          setSubmitting(false);
          resetSettings();
          message.success('Supplements saved!');
        })
        .catch((supplementsError) => {
          setSubmitting(false);
          message.error(supplementsError.message);
        });
    }
  };

  const addItem = (value) => {
    setSettings(
      settings.concat({
        key: (+new Date()).toString(36),
        value,
      }),
    );
  };

  const removeItem = (key) => {
    setSettings(settings.filter((entry) => entry.key !== key));
  };

  const changeItem = (key, value) => {
    setSettings(
      settings.map((entry) => {
        if (entry.key === key) {
          return {
            key: entry.key,
            value,
          };
        }
        return entry;
      }),
    );
  };

  let settingsToRender = settings;
  if (filteredSettingsKeys.length > 0)
    settingsToRender = settings.filter((entry) => filteredSettingsKeys.includes(entry.key));

  const settingTypeToSingular = settingType.slice(0, -1);
  return (
    <Paper style={{ marginTop: 20, padding: 20 }}>
      <AddNewItemComponent
        settingTypeToSingular={settingTypeToSingular}
        addItem={addItem}
        searchIsActive={searchIsActive}
      />
      <Divider />
      <Form>
        <Row style={{ marginBottom: 30, marginTop: 20 }}>
          <SearchComponent
            setFilteredSettingsKeys={setFilteredSettingsKeys}
            settings={settings}
            settingType={settingType}
            searchIsActive={searchIsActive}
            setSearchIsActive={setSearchIsActive}
          />
        </Row>
        <Row>
          <h1>{settingType === 'meals' ? 'Meals' : 'Supplements'}</h1>
          {settingsToRender.map((singleSetting) => {
            return (
              <SettingsItemComponent
                settings={singleSetting}
                key={singleSetting.key}
                changeItem={(key, value) => changeItem(key, value)}
                removeItem={(key) => removeItem(key)}
                settingTypeToSingular={settingTypeToSingular}
                settingType={settingType}
              />
            );
          })}
        </Row>
      </Form>
      <Button
        type="primary"
        onClick={saveItems}
        loading={submitting}
        style={{ position: 'fixed', bottom: 20, right: 150 }}
      >
        Submit
      </Button>
      <Button
        icon="arrow-up"
        onClick={() => window.scrollTo(0, 0)}
        style={{ position: 'fixed', bottom: 20, right: 20 }}
      >
        Go to top
      </Button>
    </Paper>
  );
};

SettingsComponent.propTypes = {
  settingType: PropTypes.string.isRequired,
  resetSettings: PropTypes.func.isRequired,
};

SearchComponent.propTypes = {
  setFilteredSettingsKeys: PropTypes.func.isRequired,
  settings: PropTypes.array.isRequired,
  settingType: PropTypes.string.isRequired,
  searchIsActive: PropTypes.bool.isRequired,
  setSearchIsActive: PropTypes.func.isRequired,
};

AddNewItemComponent.propTypes = {
  settingTypeToSingular: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  searchIsActive: PropTypes.bool.isRequired,
};

SettingsItemComponent.propTypes = {
  settings: PropTypes.object.isRequired,
  changeItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  settingTypeToSingular: PropTypes.string.isRequired,
};
export default SettingsComponent;
