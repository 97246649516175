import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin, Button, Table, Input, Icon } from 'antd';
import moment from 'moment';
import { getCurrency, getGoogleDocsUrl } from '../utils/globals';

import paymentReceiptReportQuery from '../graphql/queries/paymentReceiptReportQuery.graphql';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, name }) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => {
  return {
    filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      let data;
      if (name === 'invoice no.') {
        data = record.invoice.number;
      } else if (name === 'receipt no.') {
        data = record.number;
      } else {
        data = record.patient[dataIndex];
      }

      return data
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  };
};

const PaymentsReport = ({ startDate, endDate }) => (
  <Query
    query={paymentReceiptReportQuery}
    variables={{ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin />;
      if (error) return <p>ERROR: {error.message}</p>;
      const { paymentReceiptReport } = data;

      const stats = {
        total: 0,
        cash: 0,
        cheque: 0,
        paytm: 0,
        razorpay: 0,
        upi: 0,
        neft: 0,
        imps: 0,
      };
      paymentReceiptReport.forEach((entry) => {
        if (!entry.cancelled) {
          stats.total += entry.amountPaid;
          switch (entry.paymentMode) {
            case 'Cash':
              stats.cash += entry.amountPaid;
              break;
            case 'Cheque':
              stats.cheque += entry.amountPaid;
              break;
            case 'UPI':
              stats.upi += entry.amountPaid;
              break;
            case 'Razorpay':
              stats.razorpay += entry.amountPaid;
              break;
            case 'Paytm':
              stats.paytm += entry.amountPaid;
              break;
            case 'NEFT':
              stats.neft += entry.amountPaid;
              break;
            case 'IMPS':
              stats.imps += entry.amountPaid;
              break;
            default:
              break;
          }
        }
      });

      return (
        <div>
          <h2 style={{ marginBottom: 25 }}>
            Payment Receipts report from <strong>{startDate.format('Do MMMM, YYYY')}</strong> to{' '}
            <strong>{endDate.format('Do MMMM, YYYY')}</strong>
          </h2>
          <table className="reportsStatsTable" style={{ marginBottom: 30 }}>
            <tbody>
              <tr>
                <td>Total Paid</td>
                <th>{getCurrency(stats.total)}</th>
              </tr>
              <tr>
                <td>Cash</td>
                <th>{getCurrency(stats.cash)}</th>
              </tr>
              <tr>
                <td>Paytm</td>
                <th>{getCurrency(stats.paytm)}</th>
              </tr>
              <tr>
                <td>Razorpay</td>
                <th>{getCurrency(stats.razorpay)}</th>
              </tr>
              <tr>
                <td>UPI</td>
                <th>{getCurrency(stats.upi)}</th>
              </tr>
              <tr>
                <td>NEFT</td>
                <th>{getCurrency(stats.neft)}</th>
              </tr>
              <tr>
                <td>IMPS</td>
                <th>{getCurrency(stats.imps)}</th>
              </tr>
              <tr>
                <td>Cheque</td>
                <th>{getCurrency(stats.cheque)}</th>
              </tr>
            </tbody>
          </table>
          <Table loading={loading} dataSource={paymentReceiptReport} rowKey="id" pagination={false} bordered>
            <Column
              title={<strong>Date</strong>}
              key="date"
              render={(text, record) => moment(record.date, 'YYYY-MM-DD').format('Do MMM, YYYY')}
            />
            <Column
              title={<strong>Amount</strong>}
              key="amount"
              render={(text, record) => {
                return getCurrency(record.amountPaid);
              }}
            />
            <Column
              title={<strong>Receipt #</strong>}
              key="receipt"
              render={(text, record) => {
                return record.number;
              }}
              {...getColumnSearchProps('number', 'receipt no.')}
            />
            <Column
              title={<strong>Status</strong>}
              key="status"
              render={(text, record) => {
                return record.cancelled ? 'Cancelled' : 'Paid';
              }}
              filters={[
                {
                  text: 'Cancelled',
                  value: true,
                },
                {
                  text: 'Paid',
                  value: false,
                },
              ]}
              onFilter={(value, record) => {
                if (record.cancelled === value) {
                  return record;
                }
                return null;
              }}
            />
            <Column
              title={<strong>Invoice #</strong>}
              key="invoice"
              render={(text, record) => {
                return record.invoice.number;
              }}
              {...getColumnSearchProps('number', 'invoice no.')}
            />
            <Column
              title={<strong>Patient</strong>}
              key="patient"
              render={(text, record) => {
                return (
                  <>
                    {record.patient.name} <br /> +91-{record.patient.mobile}
                  </>
                );
              }}
              {...getColumnSearchProps('name', 'name')}
            />
            <Column
              title={<strong>Payment Mode</strong>}
              key="payment"
              render={(text, record) => {
                return (
                  <>
                    <strong>{record.paymentMode}</strong>
                    <br />
                    {record.paymentDetails && record.paymentDetails.referenceNo
                      ? record.paymentDetails.referenceNo
                      : '-'}
                  </>
                );
              }}
            />
            <Column
              title={<strong>Actions</strong>}
              key="actions"
              render={(text, record) => {
                return (
                  <>
                    <Button href={getGoogleDocsUrl(record.googleDocId)} target="_blank">
                      Print Receipt
                    </Button>
                    <Button href={getGoogleDocsUrl(record.invoice.googleDocId)} target="_blank">
                      Print Invoice
                    </Button>
                  </>
                );
              }}
            />
          </Table>
        </div>
      );
    }}
  </Query>
);

PaymentsReport.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default PaymentsReport;
