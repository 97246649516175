/* eslint-disable spaced-comment */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment';
import { message } from 'antd';
import Link from '../components/Link';
import Header from '../components/Header';
import Dashboard from '../pages/DoctorDashboard';
import DoctorAppointment from '../pages/DoctorAppointment';
import DietCenterDashboard from '../pages/DietCenterDashboard';
import DoctorSubscriptionPage from '../pages/DoctorSubscriptionPage';
import DoctorSubscriptionsDashboardPage from '../pages/DoctorSubscriptionsDashboardPage';
import PatientPage from '../pages/PatientPage';
import InvoicePaymentPage from '../pages/InvoicePaymentPage';
import PatientReceptionistCheckout from '../pages/PatientReceptionistCheckout';
import ViewAppointmentsCalendarPage from '../pages/ViewAppointmentsCalendarPage';
import ReportsPage from '../pages/ReportsPage';
import SettingsPage from '../pages/SettingsPage';

const DoctorLayout = (props) => {
  const { match, userName } = props;
  const { path, url } = match;

  useEffect(() => {
    const day = moment().day();
    if (day === 2) message.info('Please Review Anomaly Subscription');
  }, []);

  return (
    <div id="body">
      <Header userName={userName} />
      <AppBar color="primary" position="static">
        <Toolbar style={{ minHeight: '30px', backgroundColor: '#B10029' }}>
          <Link to="/doctor">
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Dashboard
            </Button>
          </Link>
          <Link to={`${url}/subscriptions`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Subscriptions
            </Button>
          </Link>
          <Link to={`${url}/appointments`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Appointments
            </Button>
          </Link>
          <Link to={`${url}/reports`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Reports
            </Button>
          </Link>
          <Link to={`${url}/settings`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Settings
            </Button>
          </Link>
          {/*<Link to={`${path}/dc`}>*/}
          {/*<Button color="default" style={{ color: '#FFFFFF' }}>*/}
          {/*Diet Center*/}
          {/*</Button>*/}
          {/*</Link>*/}
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/appointment/:appointmentId`} component={DoctorAppointment} />
        <Route path={`${path}/appointments`} component={ViewAppointmentsCalendarPage} />
        <Route path={`${path}/checkout/:appointmentId`} component={PatientReceptionistCheckout} />
        <Route path={`${path}/patients/:id`} component={PatientPage} />
        <Route path={`${path}/invoice/:invoiceId/payment`} component={InvoicePaymentPage} />
        <Route path={`${path}/dc`} component={DietCenterDashboard} />
        <Route path={`${path}/subscription/:id`} component={DoctorSubscriptionPage} />
        <Route path={`${path}/subscriptions`} component={DoctorSubscriptionsDashboardPage} />
        <Route path={`${path}/reports`} component={ReportsPage} />
        <Route path={`${path}/settings`} component={SettingsPage} />
        <Redirect to={`${path}`} />
      </Switch>
    </div>
  );
};

DoctorLayout.propTypes = {
  userName: PropTypes.string.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default DoctorLayout;
