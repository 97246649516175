import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import authToken from '../auth/authToken';

import { baseUrl } from '../utils/globals';

const apolloCache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: `${baseUrl}/graphql`,
});

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${authToken.getToken()}`,
  },
}));

export default new ApolloClient({
  cache: apolloCache,
  link: authLink.concat(httpLink),
});
