import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Select, DatePicker, message } from 'antd';
import AmountDueReport from '../components/AmountDueReport';
import AppointmentsReport from '../components/AppointmentsReport';
import SmsReport from '../components/SmsReport';
import InvoiceReport from '../components/InvoiceReport';
import PaymentsReport from '../components/PaymentsReport';
import SubscriptionsDpReport from '../components/SubscriptionsDpReport';

const { Option } = Select;

class ReportsPage extends Component {
  state = {
    startDate: moment().startOf('month'),
    endDate: moment(),
    reportType: undefined,
  };

  startDateOnChange = (date) => {
    const { endDate } = this.state;
    if (endDate && date.isAfter(endDate)) {
      message.error('Start date cannot be after end date');
    } else {
      this.setState({ startDate: date });
    }
  };

  endDateOnChange = (date) => {
    const { startDate } = this.state;
    if (startDate && date.isBefore(startDate)) {
      message.error('End date cannot be before start date');
    } else {
      this.setState({ endDate: date });
    }
  };

  reportTypeSelectOnChange = (value) => {
    this.setState({ reportType: value });
  };

  renderReports = () => {
    const { startDate, endDate, reportType } = this.state;
    if (!startDate || !endDate || !reportType) return null;
    switch (reportType) {
      case 'ad':
        return <AmountDueReport startDate={startDate} endDate={endDate} />;
      case 'a':
        return <AppointmentsReport startDate={startDate} endDate={endDate} />;
      case 'c':
        return <SmsReport startDate={startDate} endDate={endDate} />;
      case 'i':
        return <InvoiceReport startDate={startDate} endDate={endDate} />;
      case 'p':
        return <PaymentsReport startDate={startDate} endDate={endDate} />;
      case 'sdp':
        return <SubscriptionsDpReport startDate={startDate} endDate={endDate} />;
      default:
        return null;
    }
  };

  render() {
    const { startDate, endDate, reportType } = this.state;

    return (
      <div style={{ padding: 20 }}>
        <Paper style={{ padding: 20 }} className="hideInPrint">
          <h1>Reports</h1>
          <div style={{ display: 'flex' }}>
            <div>
              <p>Start Date:</p>
              <DatePicker
                onChange={this.startDateOnChange}
                value={startDate}
                placeholder="Start Date"
                disabledDate={(current) => current.isAfter(moment())}
                format="Do MMM, YYYY"
              />
            </div>
            <div style={{ marginLeft: 15 }}>
              <p>End Date:</p>
              <DatePicker
                onChange={this.endDateOnChange}
                value={endDate}
                placeholder="End Date"
                disabledDate={(current) => current.isAfter(moment())}
                format="Do MMM, YYYY"
              />
            </div>
            <div style={{ marginLeft: 15 }}>
              <p>Report Type:</p>
              <Select
                showSearch
                style={{ width: 250 }}
                placeholder="Select type of report"
                optionFilterProp="children"
                value={reportType}
                onChange={this.reportTypeSelectOnChange}
              >
                <Option value="ad">Amount Due</Option>
                <Option value="a">Appointments</Option>
                <Option value="c">Communications (SMS)</Option>
                <Option value="i">Invoices</Option>
                <Option value="p">Payments</Option>
                <Option value="sdp">Subscriptions & Diet Plans</Option>
              </Select>
            </div>
          </div>
        </Paper>
        <div style={{ marginTop: 20, padding: 10, backgroundColor: '#FFFFFF' }}>{this.renderReports()}</div>
      </div>
    );
  }
}

export default ReportsPage;
