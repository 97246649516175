import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Link } from 'react-router-dom';
import { graphql, Query } from 'react-apollo';
import { Row, Col, Card, Spin } from 'antd';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import { Refresh } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import AppointmentsCalendarDaily from '../components/AppointmentsCalendarDaily';
import SearchPatientComponent from '../components/SearchPatientComponent';

import QUERY from '../graphql/queries/appointmentSchedulingDataQuery.graphql';
import receptionistCallListQuery from '../graphql/queries/receptionistCallListQuery.graphql';

const ReceptionistDashboard = (props) => {
  const { data, match } = props;
  const { loading } = data;
  if (loading) return <Spin />;
  const { clinicTiming, unfilledRegistrations, allPatients } = data;
  const { path } = match;

  const cardTitle = (rclRefetch) => (
    <span>
      Patients Calls List
      <IconButton
        color="primary"
        aria-label="Refresh"
        onClick={() => {
          rclRefetch();
        }}
      >
        <Refresh />
      </IconButton>
    </span>
  );

  return (
    <div style={{ padding: 20 }}>
      <Row>
        <Col span={24}>
          <Paper>
            <Card title="Appointments" bordered={false}>
              <AppointmentsCalendarDaily clinicTiming={clinicTiming} allPatients={allPatients} />
            </Card>
          </Paper>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: 20 }}>
        <Col span={7}>
          <Paper style={{ marginBottom: 15, padding: 20 }}>
            <SearchPatientComponent />
          </Paper>
          <Paper>
            <Card title="Pending Registration Forms" bordered={false} bodyStyle={{ padding: 0 }}>
              {unfilledRegistrations.length > 0 ? (
                <List>
                  {unfilledRegistrations.map((entry) => (
                    <Link
                      to={{
                        pathname: `${path}/registration/${entry.id}`,
                        state: { patientName: entry.name, patientMobile: entry.mobile },
                      }}
                      key={entry.id}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItem button>
                        <ListItemText primary={entry.name} secondary={entry.mobile} />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              ) : (
                <p style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}>No forms pending!</p>
              )}
            </Card>
          </Paper>
        </Col>
        <Col span={7}>
          <Paper>
            <Query
              query={receptionistCallListQuery}
              fetchPolicy="network-only"
              onError={(qErr) => {
                Sentry.captureException(qErr);
              }}
            >
              {({ loading: rclLoading, error, data: rclData, refetch: rclRefetch }) => {
                if (rclLoading) return <Spin />;
                if (error) return <p>ERROR: {error.message}</p>;
                const { patients } = rclData;

                return (
                  <Card title={cardTitle(rclRefetch)} bordered={false}>
                    {Array.isArray(patients) && patients.length > 0 ? (
                      patients.map((patient, index) => {
                        let style = {
                          marginTop: 15,
                          paddingTop: 15,
                          borderTop: '1px solid #999999',
                        };
                        if (index === 0) {
                          style = null;
                        }
                        return (
                          <div key={patient.id} style={style}>
                            <p>
                              <strong>Name: </strong>
                              {patient.name}
                            </p>
                            <p>
                              <strong>Mobile: </strong>
                              {patient.mobile}
                            </p>
                            <p>
                              <strong>Last appointment: </strong>
                              {moment(new Date(patient.appointments[0].start)).format('Do MMM (ddd) [at] LT')}
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <p>No calls required!</p>
                    )}
                  </Card>
                );
              }}
            </Query>
          </Paper>
        </Col>
      </Row>
    </div>
  );
};

ReceptionistDashboard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    clinicTiming: PropTypes.shape({
      mon: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
      tue: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
      wed: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
      thur: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
      fri: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
      sat: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
      sun: PropTypes.arrayOf(
        PropTypes.shape({
          startHours: PropTypes.number.isRequired,
          startMinutes: PropTypes.number.isRequired,
          endHours: PropTypes.number.isRequired,
          endMinutes: PropTypes.number.isRequired,
        }),
      ),
    }),
    unfilledRegistrations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    allPatients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default graphql(QUERY, { options: { fetchPolicy: 'network-only' } })(ReceptionistDashboard);
