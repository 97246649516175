import React from 'react';
import { hot } from 'react-hot-loader/root';
import * as Sentry from '@sentry/browser';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AuthProvider from './auth/AuthProviderComponent';
import './less/antd.less';
import './scss/global.scss';
import App from './App';
import apolloClient from './graphql/apolloClient';
import LoginPage from './pages/LoginPage';
import { CURRENT_VERSION } from './utils/globals';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://9b4e5e4a5b5a4717891c80535f80b829@sentry.io/1363590',
    release: CURRENT_VERSION,
    environment: 'production',
  });
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#EA4A51',
      main: '#B10029',
      dark: '#7A0000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#0DBEFD',
      main: '#6CF1FF',
      dark: '#008ECA',
      contrastText: '#000000',
    },
  },
});

const Root = () => (
  <MuiThemeProvider theme={theme}>
    <ApolloProvider client={apolloClient}>
      <AuthProvider persistAuth>
        <BrowserRouter>
          <Switch>
            <Route path="/auth/login" component={LoginPage} />
            <Route component={App} />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  </MuiThemeProvider>
);

export default hot(Root);
