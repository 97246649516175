import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { Query, graphql, Mutation } from 'react-apollo';
import {
  Form,
  Input,
  Button,
  Spin,
  message,
  DatePicker,
  InputNumber,
  Radio,
  AutoComplete,
  Select,
  Col,
  Row,
  Checkbox,
} from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import Paper from '@material-ui/core/Paper';

import QUERY from '../graphql/queries/patientInfoQuery.graphql';
import MUTATION from '../graphql/mutations/patientRegistrationFormMutation.graphql';
import STORE_QUERY from '../graphql/queries/appointmentSchedulingDataQuery.graphql';
import SAVE_MUTATION from '../graphql/mutations/savePatientRegistrationDetailsMutation.graphql';

import { medicalProblems, foodAllergies } from '../utils/globals';
import FloatingSaveBtn from '../components/FloatingSaveBtn';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { TextArea } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const getSaveDataString = (values) => {
  const obj = values;
  if (Object.prototype.hasOwnProperty.call(obj, 'dob') && obj.dob) {
    obj.dob = obj.dob.toObject();
  }
  if (Object.prototype.hasOwnProperty.call(obj, 'anniversaryDate') && obj.anniversaryDate) {
    obj.anniversaryDate = obj.anniversaryDate.toObject();
  }
  return JSON.stringify(obj);
};

class PatientRegistrationForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      submittingForm: true,
    });

    const { form, match, mutate, history } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { patientId } = match.params;

        mutate({
          variables: {
            patientId,
            ...values,
            dob: values.dob ? values.dob.format('YYYY-MM-DD') : null,
            anniversaryDate: values.anniversaryDate ? values.anniversaryDate.format('YYYY-MM-DD') : null,
            medicalProblems: values.medicalProblems.map((value) => parseInt(value, 10)),
            saveData: getSaveDataString(values),
          },
          update: (cache, { data: { patientRegistrationForm } }) => {
            if (patientRegistrationForm) {
              const data = cache.readQuery({
                query: STORE_QUERY,
              });
              data.unfilledRegistrations = data.unfilledRegistrations.filter(
                (entry) => entry.id !== parseInt(patientId, 10),
              );
              cache.writeQuery({ query: STORE_QUERY, data });
            }
          },
        })
          .then(() => {
            this.setState({
              submittingForm: false,
            });
            form.resetFields();
            message.success('Success!');
            history.goBack();
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form, match } = this.props;
    const { getFieldDecorator, setFieldsValue, getFieldValue, getFieldsValue, isFieldTouched } = form;
    const { submittingForm } = this.state;
    return (
      <div style={{ padding: 20 }}>
        <Mutation mutation={SAVE_MUTATION}>
          {(savePatientRegistrationDetails) => (
            <FloatingSaveBtn
              handleSave={() => {
                savePatientRegistrationDetails({
                  variables: { patientId: match.params.patientId, saveData: getSaveDataString(getFieldsValue()) },
                })
                  .then(() => message.success('Saved!'))
                  .catch((mutateErr) => {
                    console.log(mutateErr);
                    message.error(mutateErr.message);
                    Sentry.captureException(mutateErr);
                  });
              }}
            />
          )}
        </Mutation>
        <Row>
          <Col span={24}>
            <Paper style={{ padding: 30 }}>
              <Query
                query={QUERY}
                variables={{ patientId: match.params.patientId }}
                fetchPolicy="network-only"
                onError={(qErr) => {
                  Sentry.captureException(qErr);
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return <Spin />;
                  if (error) return `Error! ${error.message}`;
                  const { patientInfo } = data;
                  const initialData = {
                    firstName: patientInfo.firstName,
                    middleName: patientInfo.middleName,
                    lastName: patientInfo.lastName,
                    mobile: patientInfo.mobile,
                    email: patientInfo.email,
                    sex: patientInfo.sex,
                    ...JSON.parse(patientInfo.saveData),
                  };
                  let disableAge = Boolean(initialData.dob);
                  if (isFieldTouched('dob')) disableAge = Boolean(getFieldValue('dob'));
                  let disableExerciseLevel = !initialData.exercise;
                  let disableSmokingFrequency = !initialData.smoking;
                  let disableAlcoholConsumptionFrequency = !initialData.alcoholConsumption;
                  if (isFieldTouched('exercise')) disableExerciseLevel = !getFieldValue('exercise');
                  if (isFieldTouched('smoking')) disableSmokingFrequency = !getFieldValue('smoking');
                  if (isFieldTouched('alcoholConsumption')) {
                    disableAlcoholConsumptionFrequency = !getFieldValue('alcoholConsumption');
                  }
                  return (
                    <Fragment>
                      <h1 style={{ textAlign: 'center' }}>Registration Form</h1>
                      {patientInfo.registrationFormCompleted ? (
                        <p style={{ textAlign: 'center', marginTop: -20, marginBottom: 20, fontStyle: 'italic' }}>
                          Last updated on: {moment(patientInfo.updatedAt).format('Do MMM YYYY')}
                        </p>
                      ) : null}

                      <Form onSubmit={this.handleSubmit} layout="horizontal">
                        <FormItem hasFeedback label="First Name" {...formItemLayout}>
                          {getFieldDecorator('firstName', {
                            initialValue: initialData.firstName,
                            rules: [
                              {
                                required: true,
                                message: 'First name is required!',
                              },
                              {
                                pattern: validationRegex.charactersOnly,
                                message: 'Incorrect name!',
                              },
                            ],
                          })(<Input type="text" required disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Middle Name" {...formItemLayout}>
                          {getFieldDecorator('middleName', {
                            initialValue: initialData.middleName,
                            rules: [
                              {
                                pattern: validationRegex.charactersOnly,
                                message: 'Incorrect name!',
                              },
                            ],
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Last Name" {...formItemLayout}>
                          {getFieldDecorator('lastName', {
                            initialValue: initialData.lastName,
                            rules: [
                              {
                                required: true,
                                message: 'Last name is required!',
                              },
                              {
                                pattern: validationRegex.charactersOnly,
                                message: 'Incorrect name!',
                              },
                            ],
                          })(<Input type="text" required disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Mobile" {...formItemLayout}>
                          {getFieldDecorator('mobile', {
                            initialValue: initialData.mobile,
                            rules: [
                              {
                                required: true,
                                message: 'Mobile is required!',
                              },
                              {
                                pattern: validationRegex.mobile,
                                message: 'Incorrect mobile no!',
                              },
                            ],
                          })(<Input type="text" required disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Email" {...formItemLayout}>
                          {getFieldDecorator('email', {
                            initialValue: initialData.email ? initialData.email : null,
                            rules: [
                              {
                                type: 'email',
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<Input type="email" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Date of birth" {...formItemLayout}>
                          {getFieldDecorator('dob', { initialValue: initialData.dob ? moment(initialData.dob) : null })(
                            <DatePicker
                              disabled={submittingForm}
                              format="DD-MM-YYYY"
                              onChange={(date) => {
                                setFieldsValue({ age: date ? moment().diff(date, 'years') : null });
                              }}
                            />,
                          )}
                        </FormItem>
                        <FormItem label="Age" {...formItemLayout}>
                          {getFieldDecorator('age', {
                            initialValue: initialData.age ? initialData.age : null,
                            rules: [
                              {
                                type: 'integer',
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<InputNumber min={1} max={150} disabled={submittingForm || disableAge} />)}
                        </FormItem>
                        <FormItem label="Sex" {...formItemLayout}>
                          {getFieldDecorator('sex', {
                            initialValue: initialData.sex ? initialData.sex : 'M',
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <RadioGroup disabled={submittingForm}>
                              <Radio value="M">M</Radio>
                              <Radio value="F">F</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem hasFeedback label="Address" {...formItemLayout}>
                          {getFieldDecorator('address', {
                            initialValue: initialData.address ? initialData.address : null,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                              {
                                pattern: validationRegex.address,
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<TextArea disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Landmark" {...formItemLayout}>
                          {getFieldDecorator('landmark', {
                            initialValue: initialData.landmark ? initialData.landmark : null,
                            rules: [
                              {
                                pattern: validationRegex.name,
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="City" {...formItemLayout}>
                          {getFieldDecorator('city', {
                            initialValue: initialData.city ? initialData.city : 'Aurangabad',
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(<Input type="text" required disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem label="State" {...formItemLayout}>
                          {getFieldDecorator('state', {
                            initialValue: initialData.state ? initialData.state : 'Maharashtra',
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Pincode" {...formItemLayout}>
                          {getFieldDecorator('pincode', {
                            initialValue: initialData.pincode ? initialData.pincode : null,
                            rules: [
                              {
                                pattern: validationRegex.pincode,
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem label="Alternate Contact No." {...formItemLayout}>
                          {getFieldDecorator('alternateContactNo', {
                            initialValue: initialData.alternateContactNo ? initialData.alternateContactNo : null,
                            rules: [
                              {
                                pattern: /^[\d-]*$/,
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem label="Height" {...formItemLayout}>
                          {getFieldDecorator('height', {
                            initialValue: initialData.height ? initialData.height : null,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                              {
                                pattern: /^[\d]*$/,
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<Input type="text" required disabled={submittingForm} addonAfter="cm" />)}
                        </FormItem>
                        <FormItem label="Blood Group" {...formItemLayout}>
                          {getFieldDecorator('bloodGroup', {
                            initialValue: initialData.bloodGroup ? initialData.bloodGroup : null,
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Aadhaar" {...formItemLayout}>
                          {getFieldDecorator('aadhaar', {
                            initialValue: initialData.aadhaar ? initialData.aadhaar : null,
                            rules: [
                              {
                                pattern: validationRegex.aadhaar,
                                message: 'Incorrect value entered!',
                              },
                            ],
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem hasFeedback label="Anniversary Date" {...formItemLayout}>
                          {getFieldDecorator('anniversaryDate', {
                            initialValue: initialData.anniversaryDate ? moment(initialData.anniversaryDate) : null,
                          })(<DatePicker disabled={submittingForm} format="DD-MM-YYYY" />)}
                        </FormItem>
                        <FormItem label="Preferred language" {...formItemLayout}>
                          {getFieldDecorator('languagePreference', {
                            initialValue: initialData.languagePreference ? initialData.languagePreference : 'English',
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <RadioGroup disabled={submittingForm}>
                              <Radio value="English">English</Radio>
                              <Radio value="Hindi">Hindi</Radio>
                              <Radio value="Marathi">Marathi</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Preferred communication channel" {...formItemLayout}>
                          {getFieldDecorator('communicationChannelPreference', {
                            initialValue: initialData.communicationChannelPreference
                              ? initialData.communicationChannelPreference
                              : null,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <CheckboxGroup disabled={submittingForm}>
                              <Checkbox value="Email">Email</Checkbox>
                              <Checkbox value="WhatsApp">WhatsApp</Checkbox>
                              <Checkbox value="SMS">SMS</Checkbox>
                            </CheckboxGroup>,
                          )}
                        </FormItem>
                        <FormItem label="How did they know about us?" {...formItemLayout}>
                          {getFieldDecorator('referenceMode', {
                            initialValue: initialData.referenceMode ? initialData.referenceMode : null,
                          })(
                            <AutoComplete
                              disabled={submittingForm}
                              dataSource={['Friends', 'Family', 'Trainer/Coach', 'Internet', 'Practo']}
                            >
                              <Input type="text" />
                            </AutoComplete>,
                          )}
                        </FormItem>
                        <FormItem label="Fitness Goals" {...formItemLayout}>
                          {getFieldDecorator('fitnessGoals', {
                            initialValue: initialData.fitnessGoals ? initialData.fitnessGoals : null,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <CheckboxGroup disabled={submittingForm}>
                              <Checkbox value="Fat Loss">Fat Loss</Checkbox>
                              <Checkbox value="Muscle Gain">Muscle Gain</Checkbox>
                              <Checkbox value="General Fitness">General Fitness</Checkbox>
                              <Checkbox value="Athletic Fitness">Athletic Fitness</Checkbox>
                            </CheckboxGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Diet Type" {...formItemLayout}>
                          {getFieldDecorator('dietType', {
                            initialValue: initialData.dietType ? initialData.dietType : 'Vegetarian',
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <RadioGroup disabled={submittingForm}>
                              <Radio value="Vegetarian">Vegetarian</Radio>
                              <Radio value="Eggitarian">Eggitarian</Radio>
                              <Radio value="Non-Vegetarian">Non-Vegetarian</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Exercise?" {...formItemLayout}>
                          {getFieldDecorator('exercise', {
                            initialValue: initialData.exercise ? 1 : 0,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <RadioGroup
                              disabled={submittingForm}
                              onChange={(e) => {
                                if (e.target.value === 0) setFieldsValue({ exerciseLevel: null });
                              }}
                            >
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Exercise Level" {...formItemLayout}>
                          {getFieldDecorator('exerciseLevel', {
                            initialValue: initialData.exerciseLevel ? initialData.exerciseLevel : null,
                          })(
                            <RadioGroup disabled={submittingForm || disableExerciseLevel}>
                              <Radio value="Basic">Basic</Radio>
                              <Radio value="Intermediate">Intermediate</Radio>
                              <Radio value="Advanced">Advanced</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Smoking?" {...formItemLayout}>
                          {getFieldDecorator('smoking', {
                            initialValue: initialData.smoking ? 1 : 0,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <RadioGroup
                              disabled={submittingForm}
                              onChange={(e) => {
                                if (e.target.value === 0) setFieldsValue({ smokingFrequency: null });
                              }}
                            >
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Smoking Frequency" {...formItemLayout}>
                          {getFieldDecorator('smokingFrequency', {
                            initialValue: initialData.smokingFrequency ? initialData.smokingFrequency : null,
                          })(
                            <RadioGroup disabled={submittingForm || disableSmokingFrequency}>
                              <Radio value="Rarely">Rarely</Radio>
                              <Radio value="Regularly">Regularly</Radio>
                              <Radio value="Addicted">Addicted</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Alcohol Consumption?" {...formItemLayout}>
                          {getFieldDecorator('alcoholConsumption', {
                            initialValue: initialData.alcoholConsumption ? 1 : 0,
                            rules: [
                              {
                                required: true,
                                message: 'Field is required!',
                              },
                            ],
                          })(
                            <RadioGroup
                              disabled={submittingForm}
                              onChange={(e) => {
                                if (e.target.value === 0) setFieldsValue({ alcoholConsumption: null });
                              }}
                            >
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Alcohol Consumption Frequency" {...formItemLayout}>
                          {getFieldDecorator('alcoholConsumptionFrequency', {
                            initialValue: initialData.alcoholConsumptionFrequency
                              ? initialData.alcoholConsumptionFrequency
                              : null,
                          })(
                            <RadioGroup disabled={submittingForm || disableAlcoholConsumptionFrequency}>
                              <Radio value="Rarely">Rarely</Radio>
                              <Radio value="Regularly">Regularly</Radio>
                              <Radio value="Addicted">Addicted</Radio>
                            </RadioGroup>,
                          )}
                        </FormItem>
                        <FormItem label="Cooking Oil" {...formItemLayout}>
                          {getFieldDecorator('cookingOil', {
                            initialValue: initialData.cookingOil ? initialData.cookingOil : null,
                          })(<Input type="text" disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem label="Food Allergies" {...formItemLayout}>
                          {getFieldDecorator('foodAllergies', {
                            initialValue: initialData.foodAllergies ? initialData.foodAllergies : [],
                          })(
                            <Select mode="tags" style={{ width: '100%' }} disabled={submittingForm}>
                              {foodAllergies.map((entry) => (
                                <Option key={entry} value={entry}>
                                  {entry}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </FormItem>
                        <FormItem label="Medical Problems" {...formItemLayout}>
                          {getFieldDecorator('medicalProblems', {
                            initialValue: initialData.medicalProblems ? initialData.medicalProblems : [],
                          })(
                            <Select mode="tags" style={{ width: '100%' }} disabled={submittingForm}>
                              {medicalProblems.map((entry) => (
                                <Option key={entry.id} value={`${entry.id}`}>
                                  {entry.title}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </FormItem>
                        <FormItem label="Injuries in past two years?" {...formItemLayout}>
                          {getFieldDecorator('pastTwoYearInjuries', {
                            initialValue: initialData.pastTwoYearInjuries ? initialData.pastTwoYearInjuries : null,
                          })(<TextArea disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem label="Medication (intake schedule)" {...formItemLayout}>
                          {getFieldDecorator('medicationIntakeSchedule', {
                            initialValue: initialData.medicationIntakeSchedule
                              ? initialData.medicationIntakeSchedule
                              : null,
                          })(<TextArea disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem label="Food supplements (intake schedule)" {...formItemLayout}>
                          {getFieldDecorator('foodSupplimentsIntakeSchedule', {
                            initialValue: initialData.foodSupplimentsIntakeSchedule
                              ? initialData.foodSupplimentsIntakeSchedule
                              : null,
                          })(<TextArea disabled={submittingForm} />)}
                        </FormItem>
                        <FormItem wrapperCol={{ span: 12, offset: 5 }}>
                          <Button type="primary" htmlType="submit" loading={submittingForm}>
                            Submit
                          </Button>
                        </FormItem>
                      </Form>
                    </Fragment>
                  );
                }}
              </Query>
            </Paper>
          </Col>
        </Row>
      </div>
    );
  }
}

PatientRegistrationForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    isFieldTouched: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default graphql(MUTATION)(Form.create()(PatientRegistrationForm));
