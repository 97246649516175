import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { Popconfirm, message } from 'antd';
import { Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from './Link';

import './DailyAppointments.scss';

import NOSHOW_MUTATION from '../graphql/mutations/appointmentNoShowMutation.graphql';
import CANCEL_MUTATION from '../graphql/mutations/appointmentCancelMutation.graphql';
import QUERY from '../graphql/queries/bookingDateData.graphql';
import PatientRegistrationRatification from './PatientRegistrationRatification';

const DailyAppointments = ({ dailyAppointments, selectedDate, match, refetchDataOnMutation }) => {
  const { path } = match;
  const futureDate = selectedDate.isAfter(moment(), 'day');
  return (
    <div style={{ marginTop: 50 }}>
      {dailyAppointments.map((entry) => {
        const startTime = moment(new Date(entry.start));
        const endTime = moment(new Date(entry.end));
        const { id, name, registrationFormCompleted, ratificationOtpGeneratedAt, registrationRatified } = entry.patient;
        return (
          <div className="receptionistPatientAppointmentBox" key={entry.id}>
            <div className="receptionistPatientAppointmentTiming">
              {`${startTime.format('hh:mm A')}-${endTime.format('hh:mm A')}`}
            </div>
            <div>
              <div className="receptionistPatientAppointmentName">{name}</div>
              {entry.status === 'open' ? (
                <div className="receptionistPatientAppointmentActions">
                  {entry.firstVisit && !futureDate && !registrationFormCompleted ? (
                    <Link to={`${path}/registration/${entry.patient.id}`}>
                      <Button variant="outlined" color="primary" className="receptionistPatientAppointmentActionBtn">
                        Fill Registration Form
                      </Button>
                    </Link>
                  ) : null}
                  {!futureDate ? (
                    <Link to={`${path}/checkout/${entry.id}`}>
                      <Button variant="outlined" color="primary" className="receptionistPatientAppointmentActionBtn">
                        Check-out
                      </Button>
                    </Link>
                  ) : null}
                  {!futureDate ? (
                    <Mutation mutation={NOSHOW_MUTATION} variables={{ appointmentId: entry.id }}>
                      {(appointmentNoShow) => (
                        <Popconfirm
                          title="Mark this appointment as no show?"
                          onConfirm={() => {
                            if (moment().isAfter(startTime, 'minute')) {
                              appointmentNoShow()
                                .then(() => {
                                  refetchDataOnMutation(true);
                                })
                                .catch((mutateErr) => {
                                  console.log(mutateErr);
                                  Sentry.captureException(mutateErr);
                                  message.error(mutateErr.message);
                                });
                            } else {
                              message.error('Cannot mark future appointments as no-show');
                            }
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            className="receptionistPatientAppointmentActionBtn"
                          >
                            No-show
                          </Button>
                        </Popconfirm>
                      )}
                    </Mutation>
                  ) : null}

                  <Mutation
                    mutation={CANCEL_MUTATION}
                    update={(cache, { data: { appointmentCancel } }) => {
                      const data = cache.readQuery({
                        query: QUERY,
                        variables: {
                          date: selectedDate.date(),
                          month: selectedDate.month(),
                          year: selectedDate.year(),
                        },
                      });
                      cache.writeQuery({
                        query: QUERY,
                        variables: {
                          date: selectedDate.date(),
                          month: selectedDate.month(),
                          year: selectedDate.year(),
                        },
                        data: {
                          ...data,
                          dailyAppointments: data.dailyAppointments.filter(
                            (daEntry) => daEntry.id !== appointmentCancel.id,
                          ),
                        },
                      });
                      refetchDataOnMutation();
                    }}
                  >
                    {(appointmentCancel) => (
                      <React.Fragment>
                        <Popconfirm
                          title="Cancel this appointment?"
                          onConfirm={() => {
                            if (moment().isBefore(startTime, 'minute')) {
                              appointmentCancel({
                                variables: { appointmentId: entry.id, cancelledBy: 'patient' },
                              }).catch((mutateErr) => {
                                console.log(mutateErr);
                                Sentry.captureException(mutateErr);
                                message.error(mutateErr.message);
                              });
                            } else {
                              message.error('Can cancel only future appointments');
                            }
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            className="receptionistPatientAppointmentActionBtn"
                          >
                            Patient Cancel
                          </Button>
                        </Popconfirm>
                        <Popconfirm
                          title="Cancel this appointment?"
                          onConfirm={() => {
                            if (moment().isBefore(startTime, 'minute')) {
                              appointmentCancel({
                                variables: { appointmentId: entry.id, cancelledBy: 'clinic' },
                              }).catch((mutateErr) => {
                                console.log(mutateErr);
                                Sentry.captureException(mutateErr);
                                message.error(mutateErr.message);
                              });
                            } else {
                              message.error('Can cancel only future appointments');
                            }
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            className="receptionistPatientAppointmentActionBtn"
                          >
                            Clinic Cancel
                          </Button>
                        </Popconfirm>
                      </React.Fragment>
                    )}
                  </Mutation>
                </div>
              ) : null}

              {entry.status === 'noShow' ? <p>Marked as No Show</p> : null}

              {entry.status === 'fulfilled' ? <p>Appointment completed</p> : null}

              {/* Registration Ratification */}
              <div style={{ margin: '10px 0' }}>
                <PatientRegistrationRatification
                  otpGenerated={!!ratificationOtpGeneratedAt}
                  registrationRatified={registrationRatified}
                  patientId={id}
                  refetchDataOnMutation={refetchDataOnMutation}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

DailyAppointments.propTypes = {
  dailyAppointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedDate: PropTypes.object.isRequired,
  refetchDataOnMutation: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(DailyAppointments);
