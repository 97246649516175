import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { withApollo, Mutation } from 'react-apollo';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { Radio, message } from 'antd';
import Button from '@material-ui/core/Button';
import CalendarMonthButton from './CalendarMonthButton';
import CalendarDateButton from './CalendarDateButton';
import BookingTimelineDay from './BookingTimelineDay';
import DailyAppointments from './DailyAppointments';
import NewPatientModal from './NewPatientModal';

import QUERY from '../graphql/queries/bookingDateData.graphql';
import MUTATION from '../graphql/mutations/newAppointmentMutation.graphql';

import './AppointmentsCalendarDaily.scss';

import { daysOfWeek } from '../utils/globals';

const RadioGroup = Radio.Group;

const noOfHours = 12;
const minutesPerSlot = 15;
const noOfSlots = noOfHours * (60 / minutesPerSlot);
const monthBtnWidth = 80;

const checkTimeInArray = (time, slotsArray) => {
  let isPresent = false;
  for (let i = 0; i < slotsArray.length; i++) {
    const slot = slotsArray[i];
    if (time.isBetween(slot.from, slot.to, null, '[)')) {
      isPresent = true;
      break;
    }
  }
  return isPresent;
};
const defaultBookingDurationSlots = () => [
  { label: '15 mins', value: 1, disabled: false, warningText: null },
  { label: '30 mins', value: 2, disabled: false, warningText: null },
  { label: '45 mins', value: 3, disabled: false, warningText: null },
  { label: '1hr', value: 4, disabled: false, warningText: null },
];

class AppointmentsCalendarDaily extends Component {
  state = {
    componentWidth: 0,
    numberOfMonths: 6,
    selectedDate: moment(),
    slots: [],
    bookingStartTimeSlotId: -1,
    bookingDurationSlotsCount: 0,
    bookingDurationSlots: defaultBookingDurationSlots(),
    visitType: 'followUp',
    showNewPatientModal: false,
    selectedPatientOption: null,
    appointmentStart: null,
    appointmentEnd: null,
    bookedSlots: [],
    fulfilledSlots: [],
    blockedSlots: [],
    dailyAppointments: [],
  };

  containerRef = React.createRef();

  componentDidMount() {
    this.getDailyBookingData();
    this.setState(
      {
        componentWidth: this.containerRef.current.offsetWidth,
        numberOfMonths: Math.floor(this.containerRef.current.offsetWidth / (monthBtnWidth + 20)),
        slots: this.createSlots(),
      },
      () => {
        this.calculateTimeSlots();
      },
    );
    window.onresize = () => {
      this.setState({
        componentWidth: this.containerRef.current.offsetWidth,
        numberOfMonths: Math.floor(this.containerRef.current.offsetWidth / (monthBtnWidth + 20)),
      });
    };
  }

  getDailyBookingData = (cacheOnly = false) => {
    const { selectedDate } = this.state;
    const { client } = this.props;
    client
      .query({
        query: QUERY,
        variables: {
          date: selectedDate.date(),
          month: selectedDate.month(),
          year: selectedDate.year(),
        },
        fetchPolicy: cacheOnly ? 'cache-only' : 'cache-first',
      })
      .then(({ data }) => {
        if (!data.loading) {
          this.setState(
            {
              bookedSlots: data.dailyAppointments
                .filter((entry) => entry.status === 'open')
                .map((entry) => ({
                  from: moment(new Date(entry.start)),
                  to: moment(new Date(entry.end)),
                })),
              fulfilledSlots: data.dailyAppointments
                .filter((entry) => entry.status === 'fulfilled')
                .map((entry) => ({
                  from: moment(new Date(entry.start)),
                  to: moment(new Date(entry.end)),
                })),
              blockedSlots: data.dailyBlockedSlots.map((entry) => ({
                from: moment(new Date(entry.start)),
                to: moment(new Date(entry.end)),
              })),
              dailyAppointments: data.dailyAppointments,
            },
            () => {
              this.calculateTimeSlots();
            },
          );
        }
      });
  };

  createSlots = () => {
    const { selectedDate } = this.state;
    const date = selectedDate.date();
    const month = selectedDate.month();
    const year = selectedDate.year();

    const slots = [];
    const slotStartTime = moment({ date, month, year, hours: 9, minutes: 0 });
    for (let i = 0; i < noOfSlots; i++) {
      slots.push({
        id: i,
        active: false,
        type: 'outOfTiming',
        startTime: moment(slotStartTime),
      });
      slotStartTime.add(minutesPerSlot, 'minutes');
    }
    return slots;
  };

  handleMonthDateButton = (newDate) => {
    this.setState(
      {
        selectedDate: newDate,
      },
      () => {
        this.setState(
          {
            slots: this.createSlots(),
          },
          () => {
            this.getDailyBookingData();
          },
        );
      },
    );
  };

  calculateTimeSlots = () => {
    const { clinicTiming } = this.props;
    const {
      selectedDate,
      slots,
      bookingStartTimeSlotId,
      bookingDurationSlotsCount,
      bookedSlots,
      fulfilledSlots,
      blockedSlots,
    } = this.state;
    const date = selectedDate.date();
    const month = selectedDate.month();
    const year = selectedDate.year();

    const clinicTimingsMoment = clinicTiming[daysOfWeek[selectedDate.isoWeekday()]].map((entry) => ({
      from: moment({ date, month, year, hours: entry.startHours, minutes: entry.startMinutes }),
      to: moment({ date, month, year, hours: entry.endHours, minutes: entry.endMinutes }),
    }));

    const newSlots = [...slots];
    const time = moment({ date, month, year, hours: 9, minutes: 1 });
    let activeSlots = [];
    if (bookingStartTimeSlotId > -1) {
      this.setState({
        appointmentStart: slots[bookingStartTimeSlotId].startTime.toISOString(),
        appointmentEnd:
          bookingStartTimeSlotId + bookingDurationSlotsCount === slots.length
            ? moment(slots[bookingStartTimeSlotId + bookingDurationSlotsCount - 1].startTime)
                .add(minutesPerSlot, 'minutes')
                .toISOString()
            : slots[bookingStartTimeSlotId + bookingDurationSlotsCount].startTime.toISOString(),
      });
      activeSlots = _.range(bookingStartTimeSlotId, bookingStartTimeSlotId + bookingDurationSlotsCount);
    }
    newSlots.forEach((entry) => {
      const slot = entry;
      if (checkTimeInArray(time, clinicTimingsMoment)) slot.type = 'open';
      if (checkTimeInArray(time, blockedSlots)) slot.type = 'blocked';
      if (checkTimeInArray(time, bookedSlots)) slot.type = 'booked';
      if (checkTimeInArray(time, fulfilledSlots)) slot.type = 'fulfilled';

      slot.active = false;
      if (activeSlots.includes(slot.id)) slot.active = true;
      time.add(minutesPerSlot, 'minutes');
    });

    this.setState({
      slots: newSlots,
    });
  };

  handleSlotClick = (id) => {
    const { slots } = this.state;

    let bookingDurationSlotsCount = 2;
    if (id === slots.length - 1) {
      bookingDurationSlotsCount = 1;
    }
    if (slots[id + 1].type === 'booked' || slots[id + 1].type === 'blocked') {
      bookingDurationSlotsCount = 1;
    }

    const bookingDurationSlots = defaultBookingDurationSlots();
    for (let i = 0; i < bookingDurationSlots.length; i++) {
      const durationSlot = bookingDurationSlots[i];
      const j = id + i;
      if (j >= slots.length) {
        durationSlot.disabled = true;
        durationSlot.warningText = 'After 9 PM is not allowed';
      } else {
        const { type } = slots[j];
        if (i > 0) {
          durationSlot.warningText = bookingDurationSlots[i - 1].warningText;
          durationSlot.disabled = bookingDurationSlots[i - 1].disabled;
        }
        if (type === 'booked') {
          durationSlot.disabled = true;
          durationSlot.warningText = 'Booked';
        }
        if (type === 'blocked') {
          durationSlot.disabled = true;
          durationSlot.warningText = 'Blocked by the doctor';
        }
        if (type === 'outOfTiming') {
          durationSlot.warningText = `Outside doctor's timings`;
        }
      }
    }

    this.setState(
      {
        bookingStartTimeSlotId: id,
        bookingDurationSlotsCount,
        bookingDurationSlots,
        visitType: 'followUp',
      },
      () => {
        this.calculateTimeSlots();
      },
    );
  };

  handleDurationRadioClick = (event) => {
    this.setState(
      {
        bookingDurationSlotsCount: event.target.value,
      },
      () => {
        this.calculateTimeSlots();
      },
    );
  };

  handleCancelButtonClick = () => {
    this.setState(
      {
        bookingStartTimeSlotId: -1,
        bookingDurationSlotsCount: 0,
        bookingDurationSlots: defaultBookingDurationSlots(),
        selectedPatientOption: null,
      },
      () => {
        this.calculateTimeSlots();
      },
    );
  };

  handleNewPatientModalClose = (newPatient = null) => {
    const newState = {
      showNewPatientModal: false,
    };

    if (newPatient) {
      newState.selectedPatientOption = {
        label: `${newPatient.name} (${newPatient.mobile})`,
        value: newPatient.id,
      };
    }

    this.setState(newState);
  };

  render() {
    const {
      selectedDate,
      numberOfMonths,
      componentWidth,
      slots,
      bookingStartTimeSlotId,
      bookingDurationSlotsCount,
      bookingDurationSlots,
      visitType,
      showNewPatientModal,
      selectedPatientOption,
      appointmentStart,
      appointmentEnd,
      dailyAppointments,
    } = this.state;

    const { clinicTiming } = this.props;

    const daysOfMonth = [];
    const today = moment();
    const firstDayOfMonth = moment({ month: selectedDate.month(), year: selectedDate.year() });
    while (firstDayOfMonth.isSame(selectedDate, 'month')) {
      const dateClinicTiming = clinicTiming[daysOfWeek[firstDayOfMonth.isoWeekday()]];
      daysOfMonth.push({
        key: firstDayOfMonth.toISOString(),
        moment: moment(firstDayOfMonth),
        disabled: firstDayOfMonth.isBefore(today, 'day') || dateClinicTiming === null,
        selected: firstDayOfMonth.isSame(selectedDate, 'day'),
      });
      firstDayOfMonth.add(1, 'days');
    }

    const { allPatients } = this.props;

    return (
      <div ref={this.containerRef}>
        <NewPatientModal showModal={showNewPatientModal} closeModal={this.handleNewPatientModalClose} />

        <div style={{ marginBottom: 20 }}>
          <Button
            color="primary"
            onClick={() => {
              this.handleMonthDateButton(moment());
            }}
          >
            Today
          </Button>
          <Button
            color="primary"
            onClick={() => {
              this.handleMonthDateButton(moment().add(1, 'days'));
            }}
          >
            Tomorrow
          </Button>
        </div>

        {/* Months */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          {_.range(numberOfMonths).map((value) => {
            const monthMoment = moment().add(value, 'months');
            return (
              <CalendarMonthButton
                key={monthMoment.toISOString()}
                month={monthMoment.month()}
                year={monthMoment.year()}
                selected={monthMoment.isSame(selectedDate, 'month')}
                clickHandler={this.handleMonthDateButton}
              />
            );
          })}
        </div>

        {/* Dates */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 40 }}>
          {daysOfMonth.map((entry) => (
            <CalendarDateButton
              key={entry.key}
              moment={entry.moment}
              disabled={entry.disabled}
              selected={entry.selected}
              clickHandler={this.handleMonthDateButton}
            />
          ))}
        </div>

        <BookingTimelineDay
          componentWidth={componentWidth}
          slotsState={slots}
          slotClickHandler={this.handleSlotClick}
        />

        {bookingStartTimeSlotId > -1 ? (
          <div className="bookingFormBox">
            <h2 style={{ marginBottom: 15 }}>Book New Appointment</h2>
            <div>
              Visit Type:
              <RadioGroup
                value={visitType}
                onChange={(event) => {
                  if (event.target.value === 'first') {
                    this.setState(
                      {
                        bookingDurationSlotsCount: 4,
                      },
                      () => {
                        this.calculateTimeSlots();
                      },
                    );
                  } else if (bookingDurationSlotsCount !== 2) {
                    this.setState(
                      {
                        bookingDurationSlotsCount: 2,
                      },
                      () => {
                        this.calculateTimeSlots();
                      },
                    );
                  }
                  this.setState({
                    visitType: event.target.value,
                    selectedPatientOption: null,
                  });
                }}
                style={{ marginLeft: 10, marginBottom: 15 }}
              >
                <Radio value="first" disabled={bookingDurationSlots[3].disabled}>
                  First Visit
                </Radio>
                <Radio value="followUp">Follow Up</Radio>
              </RadioGroup>
            </div>
            <p>
              Booking appointment on <strong>{selectedDate.format('Do MMMM, YYYY')}</strong> at{' '}
              <strong>{slots[bookingStartTimeSlotId].startTime.format('hh:mm A')}</strong> for:
            </p>
            <RadioGroup
              onChange={this.handleDurationRadioClick}
              value={bookingDurationSlotsCount}
              style={{ display: 'flex' }}
            >
              {bookingDurationSlots.map((entry) => (
                <Radio key={entry.label} value={entry.value} disabled={entry.disabled}>
                  {entry.label}
                  <br />
                  <span style={{ marginLeft: 22, fontStyle: 'italic', color: 'red' }}>{entry.warningText}</span>
                </Radio>
              ))}
            </RadioGroup>

            <h3 style={{ marginTop: 10 }}>Select patient</h3>
            <Select
              isClearable
              isSearchable
              name="appointmentPatientId"
              value={selectedPatientOption}
              onChange={(selectedOption) => {
                this.setState({ selectedPatientOption: selectedOption });
              }}
              options={allPatients.map((patient) => ({
                label: `${patient.name} (${patient.mobile})`,
                value: patient.id,
              }))}
              isDisabled={visitType === 'first'}
            />
            <div style={{ marginTop: 15 }}>
              {visitType === 'first' ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({
                      showNewPatientModal: true,
                    });
                  }}
                  style={{ marginRight: 10 }}
                >
                  Add new patient
                </Button>
              ) : null}
              <Mutation
                mutation={MUTATION}
                update={(cache, { data: { newAppointment } }) => {
                  const data = cache.readQuery({
                    query: QUERY,
                    variables: {
                      date: selectedDate.date(),
                      month: selectedDate.month(),
                      year: selectedDate.year(),
                    },
                  });
                  cache.writeQuery({
                    query: QUERY,
                    variables: {
                      date: selectedDate.date(),
                      month: selectedDate.month(),
                      year: selectedDate.year(),
                    },
                    data: { ...data, dailyAppointments: [...data.dailyAppointments, newAppointment] },
                  });
                  this.getDailyBookingData();
                }}
              >
                {(newAppointment) => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (selectedPatientOption === null) {
                        message.error('Select patient before booking appointment');
                        return;
                      }
                      if (appointmentStart === null || appointmentEnd === null) {
                        message.error('Select time slot before booking appointment');
                        return;
                      }
                      newAppointment({
                        variables: {
                          patientId: selectedPatientOption.value,
                          start: appointmentStart,
                          end: appointmentEnd,
                          firstVisit: visitType === 'first',
                        },
                      })
                        .then(() => {
                          message.success('Appointment booked!');
                          this.handleCancelButtonClick();
                        })
                        .catch((mutateErr) => {
                          console.log(mutateErr);
                          Sentry.captureException(mutateErr);
                          message.error(mutateErr.message);
                        });
                    }}
                    style={{ marginRight: 10 }}
                  >
                    Book Appointment
                  </Button>
                )}
              </Mutation>
              <Button variant="contained" color="primary" onClick={this.handleCancelButtonClick}>
                Cancel Booking
              </Button>
            </div>
          </div>
        ) : null}

        <DailyAppointments
          dailyAppointments={dailyAppointments}
          selectedDate={selectedDate}
          refetchDataOnMutation={this.getDailyBookingData}
        />
      </div>
    );
  }
}

AppointmentsCalendarDaily.propTypes = {
  clinicTiming: PropTypes.shape({
    mon: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
    tue: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
    wed: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
    thur: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
    fri: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
    sat: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
    sun: PropTypes.arrayOf(
      PropTypes.shape({
        startHours: PropTypes.number.isRequired,
        startMinutes: PropTypes.number.isRequired,
        endHours: PropTypes.number.isRequired,
        endMinutes: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  allPatients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      mobile: PropTypes.string.isRequired,
    }),
  ).isRequired,
  client: PropTypes.object.isRequired,
};

export default withApollo(AppointmentsCalendarDaily);
