import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import _ from 'lodash';
import { Query } from 'react-apollo';
import { Table, Button as AntdButton, Input, Icon } from 'antd';
import Paper from '@material-ui/core/Paper/Paper';
import Button from '@material-ui/core/Button/Button';
import withUserRole from '../auth/withUserRole';
import Link from '../components/Link';
import { appointmentStatusDescription } from '../utils/globals';
import CalendarMonthButton from '../components/CalendarMonthButton';
import CalendarDateButton from '../components/CalendarDateButton';

import dailyAppointmentsViewQuery from '../graphql/queries/dailyAppointmentsViewQuery.graphql';

const { Column } = Table;

class ViewAppointmentsCalendarPage extends Component {
  state = {
    selectedDate: moment(),
  };

  handleMonthDateButton = (newDate) => {
    this.setState({
      selectedDate: newDate,
    });
  };

  getColumnSearchProps = (dataIndex, name) => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search by ${name}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="button"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record.patient[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
    };
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  render() {
    const { selectedDate } = this.state;
    const { userRole } = this.props;

    const daysOfMonth = [];
    const firstDayOfMonth = moment({ month: selectedDate.month(), year: selectedDate.year() });
    while (firstDayOfMonth.isSame(selectedDate, 'month')) {
      daysOfMonth.push({
        key: firstDayOfMonth.toISOString(),
        moment: moment(firstDayOfMonth),
        disabled: false,
        selected: firstDayOfMonth.isSame(selectedDate, 'day'),
      });
      firstDayOfMonth.add(1, 'days');
    }

    return (
      <div style={{ padding: 20 }}>
        <h1>Past Appointments</h1>
        <Paper style={{ padding: 20 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              this.handleMonthDateButton(moment());
            }}
          >
            Go to Today
          </Button>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 auto 20px', width: 900 }}>
            {_.range(-6, 2).map((value) => {
              const monthMoment = moment().add(value, 'months');
              return (
                <CalendarMonthButton
                  key={monthMoment.toISOString()}
                  month={monthMoment.month()}
                  year={monthMoment.year()}
                  selected={monthMoment.isSame(selectedDate, 'month')}
                  clickHandler={this.handleMonthDateButton}
                  allowBackDates
                />
              );
            })}
          </div>
          {/* Dates */}
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 40 }}>
            {daysOfMonth.map((entry) => (
              <CalendarDateButton
                key={entry.key}
                moment={entry.moment}
                disabled={entry.disabled}
                selected={entry.selected}
                clickHandler={this.handleMonthDateButton}
              />
            ))}
          </div>

          <Query
            variables={{ year: selectedDate.year(), month: selectedDate.month(), date: selectedDate.date() }}
            query={dailyAppointmentsViewQuery}
            fetchPolicy="network-only"
            onError={(qErr) => {
              Sentry.captureException(qErr);
            }}
          >
            {({ loading, error, data }) => {
              if (error) return <p>ERROR! {error.messsage}</p>;
              return (
                <Table
                  loading={loading}
                  dataSource={data.dailyAppointmentsView}
                  pagination={false}
                  bordered
                  rowKey="id"
                >
                  <Column
                    title="Start Time"
                    key="start"
                    render={(text, record) => moment(new Date(record.start)).format('LT')}
                  />
                  <Column
                    title="End Time"
                    key="end"
                    render={(text, record) => moment(new Date(record.end)).format('LT')}
                  />
                  <Column
                    title="Status"
                    key="status"
                    dataIndex="status"
                    filters={[
                      {
                        text: 'Cancelled',
                        value: 'cancelled',
                      },
                      {
                        text: 'Checked-out',
                        value: 'fulfilled',
                      },
                      {
                        text: 'No-show',
                        value: 'noShow',
                      },
                      {
                        text: 'Open',
                        value: 'open',
                      },
                      {
                        text: 'Rescheduled',
                        value: 'rescheduled',
                      },
                    ]}
                    onFilter={(value, record) => {
                      if (value === record.status) {
                        return value;
                      }
                      return null;
                    }}
                    render={(text) => {
                      return appointmentStatusDescription[text];
                    }}
                  />
                  <Column
                    title="Patient Name"
                    key="patient.name"
                    dataIndex="patient.name"
                    {...this.getColumnSearchProps('name', 'patient name')}
                  />
                  <Column
                    title="Patient Mobile"
                    key="patient.mobile"
                    dataIndex="patient.mobile"
                    {...this.getColumnSearchProps('mobile', 'mobile')}
                  />
                  {userRole !== 'receptionist' ? (
                    <Column
                      title="Actions"
                      key="actions"
                      render={(text, record) => (
                        <Fragment>
                          {record.status === 'open' && record.doctorCheckedOut ? (
                            <Link to={`/doctor/checkout/${record.id}`}>
                              <AntdButton htmlType="button">Checkout</AntdButton>
                            </Link>
                          ) : null}
                        </Fragment>
                      )}
                    />
                  ) : null}
                </Table>
              );
            }}
          </Query>
        </Paper>
      </div>
    );
  }
}

ViewAppointmentsCalendarPage.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default withUserRole(ViewAppointmentsCalendarPage);
