import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Table, Card, Button } from 'antd';
import Paper from '@material-ui/core/Paper';
import AdminUpdatePasswordForm from '../components/AdminUpdatePasswordForm';

import allUsersQuery from '../graphql/queries/allUsersQuery.graphql';

const { Column } = Table;

class AdminDashboard extends Component {
  state = {
    user: null,
  };

  closeModal = () => {
    this.setState({
      user: null,
    });
  };

  render() {
    const { user } = this.state;

    return (
      <>
        {user ? <AdminUpdatePasswordForm showModal closeModal={this.closeModal} user={user} /> : null}
        <div style={{ padding: 20 }}>
          <Paper>
            <Card title="Users" bordered={false}>
              <Query
                query={allUsersQuery}
                fetchPolicy="network-only"
                onError={(qErr) => {
                  Sentry.captureException(qErr);
                }}
              >
                {({ loading, error, data }) => {
                  if (error) return <p>ERROR: {error.message}</p>;
                  return (
                    <Table loading={loading} dataSource={data.allUsers} rowKey="id" pagination={false} bordered>
                      <Column title="Email" key="email" dataIndex="email" />
                      <Column title="Role" key="role" dataIndex="role" />
                      <Column
                        title="Actions"
                        key="actions"
                        render={(text, record) => (
                          <Button
                            htmlType="button"
                            onClick={() => {
                              this.setState({
                                user: record,
                              });
                            }}
                          >
                            Update Password
                          </Button>
                        )}
                      />
                    </Table>
                  );
                }}
              </Query>
            </Card>
          </Paper>
        </div>
      </>
    );
  }
}

// AdminDashboard.propTypes = {
// };

export default AdminDashboard;
