import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment';
import { message } from 'antd';
import Link from '../components/Link';
import Header from '../components/Header';
import Dashboard from '../pages/ReceptionistDashboard';
import PatientRegistrationForm from '../forms/PatientRegistrationForm';
import PatientReceptionistCheckout from '../pages/PatientReceptionistCheckout';
import ViewAppointmentsCalendarPage from '../pages/ViewAppointmentsCalendarPage';
import InvoicePaymentPage from '../pages/InvoicePaymentPage';
import PatientPage from '../pages/PatientPage';
import ReceptionistSubscriptionsDashboardPage from '../pages/ReceptionistSubscriptionsDashboardPage';
import SettingsPage from '../pages/SettingsPage';
import UpdatePatientBasicDetails from '../pages/UpdatePatientBasicDetails';
import ReceptionistReportsPage from '../pages/ReceptionistReportsPage';

const ReceptionistLayout = ({ match, userName }) => {
  const { path, url } = match;

  useEffect(() => {
    const day = moment().day();
    if (day === 2) message.info('Please Review Anomaly Subscription');
  }, []);

  return (
    <div id="body">
      <Header userName={userName} />
      <AppBar color="primary" position="static">
        <Toolbar style={{ minHeight: '30px', backgroundColor: '#B10029' }}>
          <Link to="/receptionist">
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Dashboard
            </Button>
          </Link>
          <Link to={`${url}/appointments`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Appointments
            </Button>
          </Link>
          <Link to={`${url}/subscriptions`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Subscriptions
            </Button>
          </Link>
          <Link to={`${url}/settings`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Settings
            </Button>
          </Link>
          <Link to={`${url}/reports`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Reports
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/registration/:patientId`} component={PatientRegistrationForm} />
        <Route path={`${path}/checkout/:appointmentId`} component={PatientReceptionistCheckout} />
        <Route path={`${path}/appointments`} component={ViewAppointmentsCalendarPage} />
        <Route path={`${path}/invoice/:invoiceId/payment`} component={InvoicePaymentPage} />
        <Route path={`${path}/patients/:id`} component={PatientPage} />
        <Route path={`${path}/patient/:patientId/basicDetails/edit`} component={UpdatePatientBasicDetails} />
        <Route path={`${path}/subscriptions`} component={ReceptionistSubscriptionsDashboardPage} />
        <Route path={`${path}/settings`} component={SettingsPage} />
        <Route path={`${path}/reports`} component={ReceptionistReportsPage} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

ReceptionistLayout.propTypes = {
  userName: PropTypes.string.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReceptionistLayout;
