import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Mutation, Query } from 'react-apollo';
import { Button, Popconfirm, Table } from 'antd';
import moment from 'moment';
import withUserRole from '../auth/withUserRole';
import Link from './Link';
import UpdateSmsServiceComponent from './UpdateSmsServiceComponent';

import patientSubscriptionsQuery from '../graphql/queries/patientSubscriptionsQuery.graphql';
import PauseSubscriptionMutation from '../graphql/mutations/pauseSubscriptionMutation.graphql';
import StopSubscriptionMutation from '../graphql/mutations/stopSubscriptionMutation.graphql';
import ResumeSubscriptionMutation from '../graphql/mutations/resumeSubscriptionMutation.graphql';

const { Column } = Table;

const PatientSubscriptionsTable = ({ patientId, userRole }) => (
  <Query
    variables={{ patientId }}
    query={patientSubscriptionsQuery}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (error) return <p>ERROR: {error.message}</p>;
      return (
        <Table loading={loading} dataSource={data.patientSubscriptions} rowKey="id" pagination={false} bordered>
          <Column title="Id" key="id" dataIndex="id" />
          <Column title="Status" key="status" dataIndex="status" />
          <Column
            title="Start Date"
            key="start"
            render={(text, record) => {
              if (record.startDate) {
                return moment(new Date(record.startDate)).format('Do MMM, YYYY');
              }
              return null;
            }}
          />
          <Column
            title="End Date"
            key="end"
            render={(text, record) => (record.endDate ? moment(new Date(record.endDate)).format('Do MMM, YYYY') : null)}
          />
          <Column title="Total Plans" key="dietPlansCount" dataIndex="dietPlansCount" />
          <Column
            title="Ongoing Diet Plan"
            key="dietPlan"
            render={(text, record) => {
              if (record.status !== 'on' && record.status !== 'paused') {
                return '-';
              }
              if (
                record.currentActiveDietPlanPosition &&
                Array.isArray(record.dietPlans) &&
                record.currentActiveDietPlanPosition <= record.dietPlans.length &&
                record.currentActiveDietPlanPosition > 0
              ) {
                const dp = record.dietPlans[record.currentActiveDietPlanPosition - 1];
                const dpSD = moment(new Date(dp.startDate));
                return (
                  <div>
                    <p>
                      <strong>Diet Plan - {dp.subscriptionSequencePosition}</strong>
                    </p>
                    <p>
                      <strong>Start Date: </strong>
                      {dpSD.format('Do MMM, YYYY')}
                    </p>
                    <p>
                      <strong>End Date: </strong>
                      {moment(dpSD)
                        .add(dp.defaultValidityDays - 1, 'days')
                        .format('Do MMM, YYYY')}
                    </p>
                  </div>
                );
              }
              return 'No active diet plan';
            }}
          />
          <Column title="SMS Service" key="sms" render={(text, record) => (record.sendDietPlanSms ? 'ON' : 'OFF')} />
          <Column
            title="Actions"
            key="actions"
            render={(text, record) => (
              <div>
                {userRole !== 'receptionist' ? (
                  <Link to={`/doctor/subscription/${record.id}`} target="_blank">
                    <Button htmlType="button">View</Button>
                  </Link>
                ) : null}
                {record.status === 'on' ? <UpdateSmsServiceComponent subscription={record} /> : null}
                {/* Pause Subscription */}
                {userRole !== 'receptionist' && record.status === 'on' ? (
                  <Mutation
                    mutation={PauseSubscriptionMutation}
                    refetchQueries={['patientSubscriptions']}
                    variables={{ id: record.id }}
                    onError={(mutErr) => {
                      Sentry.captureException(mutErr);
                    }}
                  >
                    {(pauseSubscription, { loading: mutLoading }) => (
                      <Button htmlType="button" onClick={pauseSubscription} loading={mutLoading}>
                        Pause Subscription
                      </Button>
                    )}
                  </Mutation>
                ) : null}
                {/* Resume paused subscription */}
                {userRole !== 'receptionist' && record.status === 'paused' ? (
                  <Mutation
                    mutation={ResumeSubscriptionMutation}
                    refetchQueries={['patientSubscriptions']}
                    variables={{ id: record.id }}
                    onError={(mutErr) => {
                      Sentry.captureException(mutErr);
                    }}
                  >
                    {(resumeSubscription) => (
                      <Button htmlType="button" onClick={resumeSubscription}>
                        Resume Subscription
                      </Button>
                    )}
                  </Mutation>
                ) : null}
                {/* Stop Subscription action button */}
                {userRole !== 'receptionist' && record.status !== 'ended' && record.status !== 'stopped' ? (
                  <Mutation
                    mutation={StopSubscriptionMutation}
                    refetchQueries={['patientSubscriptions']}
                    variables={{ subscriptionId: record.id }}
                    onError={(mutErr) => {
                      Sentry.captureException(mutErr);
                    }}
                  >
                    {(stopSubscription, { loading: mutLoading }) => (
                      <Popconfirm
                        title={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <span>
                            Are you sure you want to stop subscription?
                            <br />
                            <span style={{ color: 'red', fontWeight: 'bold' }}>CANNOT BE UNDONE</span>
                          </span>
                        }
                        onConfirm={stopSubscription}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button htmlType="button" loading={mutLoading}>
                          Stop Subscription
                        </Button>
                      </Popconfirm>
                    )}
                  </Mutation>
                ) : null}
              </div>
            )}
          />
        </Table>
      );
    }}
  </Query>
);

PatientSubscriptionsTable.propTypes = {
  userRole: PropTypes.string.isRequired,
  patientId: PropTypes.number.isRequired,
};

export default withUserRole(PatientSubscriptionsTable);
