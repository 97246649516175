import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import { Modal, Form, Input, message, Radio } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import * as compose from 'lodash/flowRight';

import STORE_QUERY from '../graphql/queries/appointmentSchedulingDataQuery.graphql';
import MUTATION from '../graphql/mutations/newPatientMutation.graphql';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class NewPatientModal extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = () => {
    this.setState({
      submittingForm: true,
    });

    const { form, mutate, closeModal } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({
          variables: values,
          update: (cache, { data: { newPatient } }) => {
            const data = cache.readQuery({
              query: STORE_QUERY,
            });
            data.allPatients.push(newPatient);
            cache.writeQuery({ query: STORE_QUERY, data });
          },
        })
          .then(({ data }) => {
            this.setState({
              submittingForm: false,
            });
            form.resetFields();
            message.success('New patient added!');
            closeModal(data.newPatient);
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            Sentry.captureException(mutateErr);
            message.error(mutateErr.message);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form, closeModal, showModal } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <Modal
        title="Add new patient"
        visible={showModal}
        okText="Submit"
        onOk={this.handleSubmit}
        okButtonProps={{ loading: submittingForm }}
        onCancel={closeModal}
      >
        <Form layout="horizontal">
          <FormItem hasFeedback label="First Name" {...formItemLayout}>
            {getFieldDecorator('firstName', {
              rules: [
                {
                  required: true,
                  message: 'First name is required!',
                },
                {
                  pattern: validationRegex.charactersOnly,
                  message: 'Incorrect name!',
                },
              ],
            })(<Input type="text" required disabled={submittingForm} />)}
          </FormItem>
          <FormItem hasFeedback label="Middle Name" {...formItemLayout}>
            {getFieldDecorator('middleName', {
              rules: [
                {
                  pattern: validationRegex.charactersOnly,
                  message: 'Incorrect name!',
                },
              ],
            })(<Input type="text" disabled={submittingForm} />)}
          </FormItem>
          <FormItem hasFeedback label="Last Name" {...formItemLayout}>
            {getFieldDecorator('lastName', {
              rules: [
                {
                  required: true,
                  message: 'Last name is required!',
                },
                {
                  pattern: validationRegex.charactersOnly,
                  message: 'Incorrect name!',
                },
              ],
            })(<Input type="text" required disabled={submittingForm} />)}
          </FormItem>
          <FormItem hasFeedback label="Mobile" {...formItemLayout}>
            {getFieldDecorator('mobile', {
              rules: [
                {
                  required: true,
                  message: 'Mobile is required!',
                },
                {
                  pattern: validationRegex.mobile,
                  message: 'Incorrect mobile no!',
                },
              ],
            })(<Input type="text" required disabled={submittingForm} />)}
          </FormItem>
          <FormItem hasFeedback label="Email" {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Incorrect value entered!',
                },
              ],
            })(<Input type="email" disabled={submittingForm} />)}
          </FormItem>
          <FormItem label="Sex" {...formItemLayout}>
            {getFieldDecorator('sex', {
              initialValue: 'M',
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
              ],
            })(
              <RadioGroup disabled={submittingForm}>
                <Radio value="M">M</Radio>
                <Radio value="F">F</Radio>
              </RadioGroup>,
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

NewPatientModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(NewPatientModal);
