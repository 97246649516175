import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin, Input, Button, Icon, Table } from 'antd';
import moment from 'moment';
import { appointmentStatusDescription } from '../utils/globals';

import appointmentsReportQuery from '../graphql/queries/appointmentsReportQuery.graphql';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, name }) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => {
  return {
    filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      let data;
      if (name === 'status') {
        data = record[dataIndex];
      } else {
        data = record.patient[dataIndex];
      }
      return data
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  };
};

const AppointmentsReport = ({ startDate, endDate }) => (
  <Query
    query={appointmentsReportQuery}
    variables={{
      startDate: { year: startDate.year(), month: startDate.month(), day: startDate.date() },
      endDate: { year: endDate.year(), month: endDate.month(), day: endDate.date() },
    }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin />;
      if (error) return <p>ERROR: {error.message}</p>;
      const { appointmentsReport } = data;
      const stats = {
        total: appointmentsReport.length,
        fulfilled: 0,
        noShow: 0,
        cancelled: 0,
        rescheduled: 0,
        open: 0,
      };
      appointmentsReport.forEach((entry) => {
        const { status } = entry;
        if (status === 'open') stats.open += 1;
        if (status === 'rescheduled') stats.rescheduled += 1;
        if (status === 'fulfilled') stats.fulfilled += 1;
        if (status === 'noShow') stats.noShow += 1;
        if (status === 'cancelled') stats.cancelled += 1;
      });

      return (
        <div>
          <h2 style={{ marginBottom: 25 }}>
            Appointments report from <strong>{startDate.format('Do MMMM, YYYY')}</strong> to{' '}
            <strong>{endDate.format('Do MMMM, YYYY')}</strong>
          </h2>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 30 }}>
            <h3 style={{ textAlign: 'center' }}>
              Total <br /> <strong>{stats.total}</strong>
            </h3>
            <h3 style={{ textAlign: 'center' }}>
              Checked-out <br /> <strong>{stats.fulfilled}</strong>
            </h3>
            <h3 style={{ textAlign: 'center' }}>
              Open <br /> <strong>{stats.open}</strong>
            </h3>
            <h3 style={{ textAlign: 'center' }}>
              Rescheduled <br /> <strong>{stats.rescheduled}</strong>
            </h3>
            <h3 style={{ textAlign: 'center' }}>
              No-show <br /> <strong>{stats.noShow}</strong>
            </h3>
            <h3 style={{ textAlign: 'center' }}>
              Cancelled <br /> <strong>{stats.cancelled}</strong>
            </h3>
          </div>
          <Table loading={loading} dataSource={appointmentsReport} rowKey="id" pagination={false} bordered>
            <Column
              title={<span>Date</span>}
              key="date"
              render={(text, record) => {
                const startMoment = moment(new Date(record.start));
                return startMoment.format('Do MMM, YYYY');
              }}
            />
            <Column
              title={<span>Start Time</span>}
              key="start_time"
              render={(text, record) => {
                const startMoment = moment(new Date(record.start));
                return startMoment.format('LT');
              }}
            />
            <Column
              title={<span>End Time</span>}
              key="end_time"
              render={(text, record) => {
                const endMoment = moment(new Date(record.end));
                return endMoment.format('LT');
              }}
            />
            <Column
              title={<span>Patient</span>}
              key="patient"
              render={(text, record) => (
                <>
                  {record.patient.name} <br /> +91-{record.patient.mobile}
                </>
              )}
              {...getColumnSearchProps('name', 'name')}
            />
            <Column
              title={<span>Status</span>}
              key="status"
              filters={[
                { value: 'cancelled', text: 'Cancelled' },
                { value: 'fulfilled', text: 'Checked-out' },
                { value: 'noShow', text: 'No-show' },
                { value: 'open', text: 'Open' },
                { value: 'rescheduled', text: 'Rescheduled' },
              ]}
              onFilter={(value, record) => {
                if (record.status === value) {
                  return record;
                }
                return null;
              }}
              render={(text, record) => appointmentStatusDescription[record.status]}
            />
            <Column title={<span>Visit Notes</span>} key="notes" render={(text, record) => record.visitNotes} />
          </Table>
        </div>
      );
    }}
  </Query>
);

AppointmentsReport.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default AppointmentsReport;
