import lodash from 'lodash';
import moment from 'moment';

export const CURRENT_VERSION = 'v1.8.2';

let tempBaseUrl = 'https://api.ecp15.dev.cloud.eumentis.com';
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  tempBaseUrl = 'https://api.nutritoo.in';
}
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  tempBaseUrl = 'http://localhost:3015';
}

export const baseUrl = tempBaseUrl;

export const log = (data) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV) console.log(data);
};

export const roleWeight = (role) => {
  if (role === 'admin') return 100;
  if (role === 'doctor') return 50;
  return 10;
};

export const daysOfWeek = {
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thur',
  5: 'fri',
  6: 'sat',
  7: 'sun',
};

export const medicalProblems = [
  { id: 1, title: 'Obesity' },
  { id: 2, title: 'Diabetes' },
  { id: 3, title: 'Anemia' },
  { id: 4, title: 'Hypertension (High Blood Pressure)' },
  { id: 5, title: 'Hypotension (Low Blood Pressure)' },
  { id: 6, title: 'Migraine' },
  { id: 7, title: 'Hyperthyroid' },
  { id: 8, title: 'Hypothyroid' },
  { id: 9, title: 'Acidity' },
  { id: 10, title: 'Polycystic Ovary Syndrome' },
  { id: 11, title: 'Kidney Stone' },
  { id: 12, title: 'Heart Conditions' },
  { id: 13, title: 'Hormonal Imbalance' },
  { id: 14, title: 'Osteoporosis' },
  { id: 15, title: 'Stress' },
  { id: 16, title: 'Insomnia' },
];

export const medicalProblemsObj = {
  1: 'Obesity',
  2: 'Diabetes',
  3: 'Anemia',
  4: 'Hypertension (High Blood Pressure)',
  5: 'Hypotension (Low Blood Pressure)',
  6: 'Migraine',
  7: 'Hyperthyroid',
  8: 'Hypothyroid',
  9: 'Acidity',
  10: 'Polycystic Ovary Syndrome',
  11: 'Kidney Stone',
  12: 'Heart Conditions',
  13: 'Hormonal Imbalance',
  14: 'Osteoporosis',
  15: 'Stress',
  16: 'Insomnia',
};

export const getGoogleDocsUrl = (fileId) => `https://docs.google.com/document/d/${fileId}/edit`;

export const getCurrency = (value) =>
  value.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const units = [
  { key: 'tsp', name: 'teaspoon' },
  { key: 'tbsp', name: 'tablespoon' },
  { key: 'cup', name: 'cup' },
  { key: 'ml', name: 'milliliter' },
  { key: 'l', name: 'liter' },
  { key: 'mg', name: 'milligram' },
  { key: 'g', name: 'gram' },
  { key: 'kg', name: 'kilogram' },
];

export const subscriptionStatusDescription = {
  notStarted: 'Subscription not started',
  on: 'On',
  paused: 'Paused',
  ended: 'Completed',
  stopped: 'Stopped by doctor',
};

export const dietPlanStatusDescription = {
  created: 'Not created',
  yetToStart: 'Created, but not started',
  on: 'ON',
  ended: 'Ended',
};

export const appointmentStatusDescription = {
  open: 'Open',
  rescheduled: 'Rescheduled',
  fulfilled: 'Checked-out',
  noShow: 'No-show',
  cancelled: 'Cancelled',
};

export const dietPlanDefaultValues = (patient = {}, currentDietPlan = {}, lastDietPlan = {}) => {
  const initialData = {
    waterIntake: 3,
    generalGuidelines: [
      'Restrict your intake of tea and coffee.',
      'Avoid long gap between meals.',
      'Restrict fried foods, sweets, and preserved foods.',
      'Prefer tandoor, baked, grilled food stuff while eating out.',
      'No fruit juices at all and no fruit after second meal.',
      'Drink water half hour before meal',
    ],
    specialGuidelines: [
      'No wheat ,rice, South Indian, corns, sago (sabudana), banana, grapes, cheese, white bread, bakery products, red pumpkin, potato, red meat, monosodium glutamate (MSG) and junk food.',
      'Avoid taking table salt, pickle, papad and canned olive.',
      'Get adequate sleep each night',
      'Eat soluble fiber including fresh food / vegetables.',
      'Avoid chocolate, onion, pasta, bread, ice cream, corn, tomatoes, citrus fruit, dairy products, red wine, mono sodium glutamate (MSG), nutra sweet and eggs yolk.',
      'Limit take of vitamin K Reach food Brussels sprouts, spinach, broccoli, kale, banana, fish, coconut, goat cheese, custard apple, eggs yolk, parsley, prunes and cauliflower.',
      'Eat less Oxalate Food: - spinach, beet, almond, fig, okra, sweet potato, cashew, peanut, turmeric, sesame seeds and poppy seeds.',
      'Avoid having sapota, kiwi, banana, grapes, onion and cucumber, Soya, quinoa, wheat germ, cocoa and stevia sweetener.',
      "Avoid foods like soda, candy, ice creams, Dairy products, soy products, caffeine, alcohol, unhealthy fats such as saturated fats and Tran's fats.",
    ],
    entries: [
      { hours: 5, minutes: 30, mealType: 'Pre W/O', timeNote: '30-45 mins prior of w/o', meals: [], supplements: [] },
      { hours: 7, minutes: 0, mealType: 'Post W/O', timeNote: 'Within 1 hr', meals: [], supplements: [] },
      { hours: 9, minutes: 30, mealType: 'Breakfast', timeNote: null, meals: [], supplements: [] },
      { hours: 12, minutes: 30, mealType: 'Lunch', timeNote: null, meals: [], supplements: [] },
      { hours: 16, minutes: 0, mealType: 'Mid Meal', timeNote: null, meals: [], supplements: [] },
      { hours: 19, minutes: 30, mealType: 'Dinner', timeNote: null, meals: [], supplements: [] },
      { hours: 22, minutes: 0, mealType: null, timeNote: null, meals: [], supplements: [] },
    ],
    oilIntake: 10,
  };

  return lodash.assignWith(
    {},
    initialData,
    patient,
    lastDietPlan,
    currentDietPlan,
    (objValue, srcValue, key, object) => {
      if (key === 'startDate') {
        if (!srcValue) {
          const tomorrow = moment()
            .add(1, 'days')
            .startOf('day');
          if (!objValue) return tomorrow;
          let newStartDateMoment = moment(new Date(objValue))
            .add(object.defaultValidityDays + 1, 'days')
            .startOf('day');
          if (object.endDate)
            newStartDateMoment = moment(new Date(object.endDate))
              .add(1, 'days')
              .startOf('day');
          if (tomorrow.isAfter(newStartDateMoment)) return tomorrow;
          return newStartDateMoment;
        }
        return moment(new Date(srcValue));
      }
      if (key === 'endDate') return srcValue;
      if (lodash.isNull(srcValue)) return objValue;
      if (Array.isArray(srcValue) && srcValue.length < 1) return objValue;
      if (lodash.isString(srcValue) && !srcValue) return objValue;
      return srcValue;
    },
  );
};

export const foodAllergies = ['Lactose (Milk)', 'Food Additives', 'Nuts', 'Eggs', 'Fish', 'Wheat', 'Peanuts', 'Soy'];

export const invoiceStatus = (invoice) => {
  if (invoice.cancelled) return 'Cancelled';
  if (invoice.summary.balance === 0) return 'Paid';
  if (invoice.summary.totalPaid === 0) return 'Unpaid';
  return 'Partial Paid';
};
