import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import * as compose from 'lodash/flowRight';
import { Form, DatePicker, TimePicker, Input, Button, message } from 'antd';

import MUTATION from '../graphql/mutations/doctorBlockSlotMutation.graphql';

const FormItem = Form.Item;
const { TextArea } = Input;

class DoctorBlockAppointmentForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      submittingForm: true,
    });

    const { mutate, form } = this.props;

    form.validateFieldsAndScroll((err, { startDate, startTime, endDate, endTime, reason }) => {
      if (!err) {
        mutate({
          variables: {
            start: new Date(
              startDate.year(),
              startDate.month(),
              startDate.date(),
              startTime.hour(),
              startTime.minutes(),
            ).toISOString(),
            end: new Date(
              endDate.year(),
              endDate.month(),
              endDate.date(),
              endTime.hour(),
              endTime.minutes(),
            ).toISOString(),
            reason,
          },
        })
          .then(() => {
            this.setState({
              submittingForm: false,
            });
            message.success('Slot blocked');
            form.resetFields();
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { submittingForm } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem label="Start Date">
          {getFieldDecorator('startDate', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
            ],
          })(<DatePicker disabled={submittingForm} required format="DD-MM-YYYY" />)}
        </FormItem>
        <FormItem label="Start time">
          {getFieldDecorator('startTime', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
            ],
          })(<TimePicker format="hh:mm A" minuteStep={5} use12Hours disabled={submittingForm} required />)}
        </FormItem>
        <FormItem label="End Date">
          {getFieldDecorator('endDate', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
            ],
          })(<DatePicker disabled={submittingForm} required format="DD-MM-YYYY" />)}
        </FormItem>
        <FormItem label="End time">
          {getFieldDecorator('endTime', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
            ],
          })(<TimePicker format="hh:mm A" minuteStep={5} use12Hours disabled={submittingForm} required />)}
        </FormItem>
        <FormItem label="Reason">
          {getFieldDecorator('reason')(<TextArea autosize disabled={submittingForm} />)}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={submittingForm}>
            Block Slots
          </Button>
        </FormItem>
      </Form>
    );
  }
}

DoctorBlockAppointmentForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(DoctorBlockAppointmentForm);
