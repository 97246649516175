import React from 'react';
import * as Sentry from '@sentry/browser';
import { Col, Row, Card, Table, Button, message } from 'antd';
import { Query, Mutation } from 'react-apollo';
import Paper from '@material-ui/core/Paper/Paper';
import moment from 'moment';
import TodayAppointmentList from '../components/TodayAppointmentList';
import DoctorBlockAppointmentForm from '../forms/DoctorBlockAppointmentForm';
import SearchPatientComponent from '../components/SearchPatientComponent';

import BlockedSlotsQuery from '../graphql/queries/blockedSlotsQuery.graphql';
import UnblockSlotMutation from '../graphql/mutations/deleteDoctorBlockedSlotMutation.graphql';

const { Column } = Table;

const DoctorDashboard = () => (
  <div style={{ padding: 20 }}>
    <Row gutter={20}>
      <Col span={6}>
        <Paper style={{ marginBottom: 15, padding: 20 }}>
          <SearchPatientComponent />
        </Paper>
        <Paper>
          <TodayAppointmentList />
        </Paper>
      </Col>
      <Col span={18}>
        <Paper style={{ marginBottom: 15 }}>
          <Card title="Blocked Slots">
            <Query
              query={BlockedSlotsQuery}
              fetchPolicy="network-only"
              onError={(qErr) => {
                Sentry.captureException(qErr);
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (error) return <p>{error.message}</p>;
                return (
                  <Table loading={loading} dataSource={data.blockedSlots} rowKey="id" bordered>
                    <Column
                      title="Date"
                      key="date"
                      render={(text, record) => moment(new Date(record.start)).format('Do MMM, YYYY')}
                    />
                    <Column
                      title="Start Time"
                      key="start"
                      render={(text, record) => moment(new Date(record.start)).format('hh:mm A')}
                    />
                    <Column
                      title="End Time"
                      key="end"
                      render={(text, record) => moment(new Date(record.end)).format('hh:mm A')}
                    />
                    <Column title="Reason" dataIndex="reason" key="reason" />
                    <Column
                      title="Actions"
                      key="actions"
                      render={(text, record) => (
                        <Mutation update={null} mutation={UnblockSlotMutation} variables={{ id: record.id }}>
                          {(deleteDoctorBlockedSlot) => (
                            <Button
                              htmlType="button"
                              onClick={() => {
                                deleteDoctorBlockedSlot()
                                  .then(() => {
                                    message.success('Slot unblocked');
                                    refetch();
                                  })
                                  .catch((mutateErr) => {
                                    console.log(mutateErr);
                                    Sentry.captureException(mutateErr);
                                    message.error(mutateErr.message);
                                  });
                              }}
                            >
                              Unblock Slot
                            </Button>
                          )}
                        </Mutation>
                      )}
                    />
                  </Table>
                );
              }}
            </Query>
          </Card>
        </Paper>
        <Paper style={{ marginBottom: 15 }}>
          <Card title="Block appointment slots">
            <DoctorBlockAppointmentForm />
          </Card>
        </Paper>
      </Col>
    </Row>
  </div>
);

export default DoctorDashboard;
