import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Button, Table } from 'antd';
import moment from 'moment';
import Link from './Link';
import { appointmentStatusDescription } from '../utils/globals';

import patientAppointmentsQuery from '../graphql/queries/patientAppointmentsQuery.graphql';

const { Column } = Table;

const PatientAppointmentsTable = ({ patientId }) => (
  <Query
    variables={{ patientId }}
    query={patientAppointmentsQuery}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (error) return <p>ERROR: {error.message}</p>;
      return (
        <Table loading={loading} dataSource={data.patientAppointments} rowKey="id" pagination={false} bordered>
          <Column
            title="Date"
            key="date"
            render={(text, record) => moment(new Date(record.start)).format('Do MMM YYYY')}
          />
          <Column
            title="Start Time"
            key="start"
            render={(text, record) => moment(new Date(record.start)).format('LT')}
          />
          <Column title="End Time" key="end" render={(text, record) => moment(new Date(record.end)).format('LT')} />
          <Column
            title="Status"
            key="status"
            dataIndex="status"
            filters={[
              { value: 'cancelled', text: 'Cancelled' },
              { value: 'fulfilled', text: 'Checked-out' },
              { value: 'noShow', text: 'No-show' },
              { value: 'open', text: 'Open' },
              { value: 'rescheduled', text: 'Rescheduled' },
            ]}
            onFilter={(value, record) => {
              if (record.status === value) {
                return record;
              }
              return null;
            }}
            render={(text) => appointmentStatusDescription[text]}
          />
          <Column title="Visit Notes" key="visitNotes" dataIndex="visitNotes" />
          <Column
            title="Actions"
            key="actions"
            render={(text, record) => (
              <Fragment>
                {record.status === 'open' && record.doctorCheckedOut ? (
                  <Link to={`/doctor/checkout/${record.id}`}>
                    <Button htmlType="button">Checkout</Button>
                  </Link>
                ) : null}
              </Fragment>
            )}
          />
        </Table>
      );
    }}
  </Query>
);

PatientAppointmentsTable.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default PatientAppointmentsTable;
