import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import Button from '@material-ui/core/Button/Button';
import { Mutation } from 'react-apollo';
import { Form, Input, Button as AntdButton, message } from 'antd';

import GenerateRatificationOtpMutation from '../graphql/mutations/generateRatificationOtpMutation.graphql';
import RatifyPatientRegistrationMutation from '../graphql/mutations/ratifyPatientRegistrationMutation.graphql';
import ResendRatificationOtpMutation from '../graphql/mutations/resendRatificationOtpMutation.graphql';

const FormItem = Form.Item;

const PatientRegistrationRatification = ({
  patientId,
  otpGenerated,
  registrationRatified,
  form,
  refetchDataOnMutation,
}) => {
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form;
  if (registrationRatified) return <span />;
  if (!otpGenerated) {
    return (
      <Mutation
        mutation={GenerateRatificationOtpMutation}
        variables={{ id: patientId }}
        onCompleted={() => {
          refetchDataOnMutation();
        }}
        onError={(mutErr) => {
          Sentry.captureException(mutErr);
        }}
      >
        {(generateRatificationOtp) => (
          <Button
            variant="outlined"
            color="primary"
            className="receptionistPatientAppointmentActionBtn"
            onClick={generateRatificationOtp}
          >
            Generate Registration OTP
          </Button>
        )}
      </Mutation>
    );
  }

  return (
    <Mutation mutation={RatifyPatientRegistrationMutation}>
      {(ratifyPatientRegistration) => (
        <Form
          layout="inline"
          onSubmit={(e) => {
            e.preventDefault();

            validateFieldsAndScroll((err, values) => {
              if (!err) {
                ratifyPatientRegistration({ variables: { id: patientId, ...values } })
                  .then(() => {
                    resetFields();
                    refetchDataOnMutation();
                    message.success('Patient ratified!');
                  })
                  .catch((mutateErr) => {
                    console.log(mutateErr);
                    Sentry.captureException(mutateErr);
                    message.error(mutateErr.message);
                  });
              }
            });
          }}
        >
          <FormItem hasFeedback label="Enter OTP">
            {getFieldDecorator('otp', {
              rules: [
                { required: true, message: 'Field is required!' },
                { pattern: /^\d{4}$/, message: 'Incorrect value' },
              ],
            })(<Input type="text" required />)}
          </FormItem>
          <FormItem>
            <AntdButton type="primary" htmlType="submit">
              Submit
            </AntdButton>
          </FormItem>
          <FormItem>
            <Mutation
              mutation={ResendRatificationOtpMutation}
              variables={{ id: patientId }}
              onCompleted={() => {
                message.success('OTP resent');
              }}
              onError={(mutErr) => {
                Sentry.captureException(mutErr);
                message.error('Error! Try Again');
              }}
            >
              {(resendRatificationOtp) => <AntdButton onClick={resendRatificationOtp}>Resend OTP</AntdButton>}
            </Mutation>
          </FormItem>
        </Form>
      )}
    </Mutation>
  );
};

PatientRegistrationRatification.propTypes = {
  patientId: PropTypes.number.isRequired,
  otpGenerated: PropTypes.bool.isRequired,
  registrationRatified: PropTypes.bool.isRequired,
  refetchDataOnMutation: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
};

export default Form.create()(PatientRegistrationRatification);
