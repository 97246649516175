import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Button, Form, InputNumber, message } from 'antd';
import { Mutation } from 'react-apollo';
import createInvoiceRazorpayPaymentLinkMutation from '../graphql/mutations/createInvoiceRazorpayPaymentLinkMutation.graphql';

const FormItem = Form.Item;

const CreateRazorpayPaymentLinkForm = ({ invoiceId, style, refetch, form }) => (
  <Mutation mutation={createInvoiceRazorpayPaymentLinkMutation} variables={{ invoiceId }}>
    {(createInvoiceRazorpayPaymentLink, { loading: mutLoading }) => (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          form.validateFieldsAndScroll(['amount'], (err, { amount }) => {
            if (!err) {
              createInvoiceRazorpayPaymentLink({ variables: { invoiceId, amount } })
                .then(() => {
                  refetch();
                  form.resetFields();
                })
                .catch((mutErr) => {
                  console.log(mutErr);
                  Sentry.captureException(mutErr);
                  message.error(mutErr.message);
                });
            }
          });
        }}
        style={style}
      >
        <FormItem label="Amount (Rs.)" hasFeedback>
          {form.getFieldDecorator('amount', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
            ],
          })(<InputNumber required />)}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={mutLoading}>
            Send Payment Link
          </Button>
        </FormItem>
      </Form>
    )}
  </Mutation>
);

CreateRazorpayPaymentLinkForm.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  style: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
};

CreateRazorpayPaymentLinkForm.defaultProps = {
  style: null,
};

export default Form.create()(CreateRazorpayPaymentLinkForm);
