import React from 'react';
import { Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from './authContext';

const loginPagePath = '/auth/login';

const withAuth = (fullPageLoading = false) => (Component) => (props) => (
  <AuthConsumer>
    {({ userLoggedIn, user, loading, logout }) => {
      if (loading) {
        if (fullPageLoading)
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                minHeight: '100vh',
                width: '100vw',
                minWidth: '100vw',
              }}
            >
              <Spin size="large" />
            </div>
          );
        return <Spin />;
      }
      if (!userLoggedIn) return <Redirect to={loginPagePath} noThrow />;
      return <Component {...props} user={user} logout={logout} />;
    }}
  </AuthConsumer>
);

export default withAuth;
