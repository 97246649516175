import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Form, Input, Button, Card, message } from 'antd';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { AuthConsumer } from '../auth/authContext';

import { baseUrl } from '../utils/globals';

import './LoginPage.scss';

const FormItem = Form.Item;

class LoginPage extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (login) => {
    this.setState({
      submittingForm: true,
    });

    const { form, history } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        axios
          .post(`${baseUrl}/auth/login`, values)
          .then((response) => {
            this.setState({
              submittingForm: false,
            });

            // Store auth data
            login(response.data);

            history.push('/');
          })
          .catch((error) => {
            Sentry.captureException(error);
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              if (error.response.status === 422) {
                Object.values(error.response.data).forEach((msg) => {
                  message.error(msg);
                });
              } else if (error.response.status === 500) {
                message.error('Server error!');
              } else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              }
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            // console.log(error.config);
            this.setState({
              submittingForm: false,
            });
          });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <AuthConsumer>
        {({ login }) => (
          <div id="body">
            <div id="loginFormBoxContainer">
              <Paper>
                <Card title="NutriToo Login" id="loginFormBox">
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.handleSubmit(login);
                    }}
                    layout="vertical"
                  >
                    <FormItem hasFeedback label="Email">
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            message: 'Email is required!',
                          },
                          {
                            type: 'email',
                            message: 'Incorrect Email!',
                          },
                        ],
                      })(<Input type="email" required disabled={submittingForm} />)}
                    </FormItem>
                    <FormItem hasFeedback label="Password">
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Password is required!',
                          },
                        ],
                      })(<Input type="password" required disabled={submittingForm} />)}
                    </FormItem>
                    <FormItem>
                      <Button type="primary" htmlType="submit" loading={submittingForm}>
                        Login
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Paper>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

LoginPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(LoginPage);
