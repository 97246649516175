import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Col, Row, Spin } from 'antd';
import Paper from '@material-ui/core/Paper/Paper';

import SubscriptionByIdQuery from '../graphql/queries/subscriptionByIdQuery.graphql';
import ActiveSubscription from '../components/ActiveSubscription';

const DoctorSubscriptionPage = ({ match }) => (
  <Query
    variables={{ id: match.params.id }}
    query={SubscriptionByIdQuery}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data, refetch }) => {
      if (loading) return <Spin />;
      if (error) return <p>{error.message}</p>;
      const { subscription } = data;
      return (
        <div style={{ padding: 20 }}>
          <Row>
            <Col span={24}>
              <Paper style={{ padding: 20 }}>
                <h1>Subscription</h1>
                <p>
                  <strong>Client Name: </strong>
                  {subscription.patient.name}
                </p>
                <p>
                  <strong>Client Mobile: </strong>
                  {subscription.patient.mobile}
                </p>
                <ActiveSubscription subscription={subscription} refetchQuery={refetch} />
              </Paper>
            </Col>
          </Row>
        </div>
      );
    }}
  </Query>
);

DoctorSubscriptionPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DoctorSubscriptionPage;
