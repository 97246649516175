import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import { Form, Input, Radio, InputNumber, Button, message } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import * as compose from 'lodash/flowRight';

import MUTATION from '../graphql/mutations/generatePaymentReceiptMutation.graphql';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class PaymentReceiptForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      submittingForm: true,
    });
    const { mutate, invoiceId, form, refetchQuery } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({
          variables: {
            invoiceId,
            ...values,
          },
        })
          .then(() => {
            this.setState({
              submittingForm: false,
            });
            form.resetFields();
            message.success('Payment receipt generated');
            refetchQuery();
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { submittingForm } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <FormItem hasFeedback label="Amount Paid">
          {getFieldDecorator('amountPaid', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect amount entered',
              },
            ],
          })(<InputNumber min={1} disabled={submittingForm} required />)}
        </FormItem>
        <FormItem label="Payment Mode">
          {getFieldDecorator('paymentMode', {
            rules: [
              {
                required: true,
                message: 'Field is required!',
              },
            ],
          })(
            <RadioGroup disabled={submittingForm}>
              <Radio value="Cash">Cash</Radio>
              <Radio value="Card">Card</Radio>
              <Radio value="Paytm">Paytm</Radio>
              <Radio value="UPI">UPI</Radio>
              <Radio value="NEFT">NEFT</Radio>
              <Radio value="IMPS">IMPS</Radio>
              <Radio value="Cheque">Cheque</Radio>
            </RadioGroup>,
          )}
        </FormItem>
        <FormItem label="Reference #">{getFieldDecorator('referenceNo')(<Input disabled={submittingForm} />)}</FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={submittingForm}>
            Generate Payment Receipt
          </Button>
        </FormItem>
      </Form>
    );
  }
}

PaymentReceiptForm.propTypes = {
  invoiceId: PropTypes.number.isRequired,
  refetchQuery: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(PaymentReceiptForm);
