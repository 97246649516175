import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin, Button, Table, Input, Icon } from 'antd';
import moment from 'moment';
import Link from './Link';
import { getCurrency, getGoogleDocsUrl, invoiceStatus } from '../utils/globals';

import invoiceReportQuery from '../graphql/queries/invoiceReportQuery.graphql';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, name }) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => {
  return {
    filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      let data;
      if (name === 'invoice no.') {
        data = record[dataIndex];
      } else {
        data = record.patient[dataIndex];
      }
      return data
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  };
};

const InvoiceReport = ({ startDate, endDate }) => (
  <Query
    query={invoiceReportQuery}
    variables={{ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin />;
      if (error) return <p>ERROR: {error.message}</p>;
      const { invoiceReport } = data;

      const stats = {
        totalBeforeDiscount: 0,
        total: 0,
        cancelled: 0,
        discount: 0,
        paid: 0,
        due: 0,
      };
      invoiceReport.forEach((entry) => {
        if (entry.cancelled) {
          stats.cancelled += entry.summary.invoiceTotal;
        } else {
          stats.totalBeforeDiscount += entry.totalBeforeDiscount;
          stats.total += entry.summary.invoiceTotal;
          stats.discount += entry.totalBeforeDiscount - entry.summary.invoiceTotal;
          stats.paid += entry.summary.totalPaid;
          stats.due += entry.summary.balance + entry.summary.totalRazorpayIssued;
        }
      });

      return (
        <div>
          <h2 style={{ marginBottom: 25 }}>
            Invoices report from <strong>{startDate.format('Do MMMM, YYYY')}</strong> to{' '}
            <strong>{endDate.format('Do MMMM, YYYY')}</strong>
          </h2>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: 30 }}>
            <div style={{ textAlign: 'center' }}>
              <p>TOTAL INVOICED</p>
              <h3>
                <strong>{getCurrency(stats.totalBeforeDiscount + stats.cancelled)}</strong>
              </h3>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>CANCELLED</p>
              <h3>
                <strong>{getCurrency(stats.cancelled)}</strong>
              </h3>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>NET INVOICED</p>
              <h3>
                <strong>{getCurrency(stats.totalBeforeDiscount)}</strong>
              </h3>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>TOTAL DISCOUNT</p>
              <h3>
                <strong>{getCurrency(stats.discount)}</strong>
              </h3>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>NET RECEIVABLE</p>
              <h3>
                <strong>{getCurrency(stats.total)}</strong>
              </h3>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>TOTAL PAID</p>
              <h3>
                <strong>{getCurrency(stats.paid)}</strong>
              </h3>
            </div>
            <div style={{ textAlign: 'center' }}>
              <p>TOTAL DUE</p>
              <h3>
                <strong>{getCurrency(stats.due)}</strong>
              </h3>
            </div>
          </div>

          <Table loading={loading} dataSource={invoiceReport} rowKey="id" pagination={false} bordered>
            <Column
              title={<strong>Invoice Date</strong>}
              key="date"
              render={(text, record) => moment(record.date, 'YYYY-MM-DD').format('Do MMM, YYYY')}
            />
            <Column
              title={<strong>Invoice No.</strong>}
              key="number"
              dataIndex="number"
              {...getColumnSearchProps('number', 'invoice no.')}
            />
            <Column
              title={<strong>Invoice Status</strong>}
              key="invoiceStatus"
              dataIndex="invoiceStatus"
              filters={[{ value: 'Paid', text: 'Paid' }, { value: 'Unpaid', text: 'Unpaid' }]}
              onFilter={(value, record) => {
                if (invoiceStatus(record) === value) {
                  return record;
                }
                return null;
              }}
              render={(text, record) => invoiceStatus(record)}
            />
            <Column
              title={<strong>Patient</strong>}
              key="patient"
              dataIndex="patient"
              render={(text, record) => (
                <>
                  {record.patient.name} <br /> +91-{record.patient.mobile}
                </>
              )}
              {...getColumnSearchProps('name', 'name')}
            />
            <Column
              title={<strong>Invoice Total</strong>}
              key="summary.invoiceTotal"
              dataIndex="summary.invoiceTotal"
              render={(text, record) => {
                return <span style={{ float: 'right' }}>{getCurrency(record.summary.invoiceTotal)}</span>;
              }}
            />
            <Column
              title={<strong>Amount Due</strong>}
              key="summary.balance"
              dataIndex="summary.balance"
              render={(text, record) => {
                return (
                  <span style={{ float: 'right' }}>
                    {record.cancelled
                      ? getCurrency(0)
                      : getCurrency(record.summary.balance + record.summary.totalRazorpayIssued)}
                  </span>
                );
              }}
            />
            <Column
              title={<strong>Actions</strong>}
              key="actions"
              dataIndex="actions"
              className="hideInPrint"
              render={(text, record) => {
                return (
                  <>
                    <Button href={getGoogleDocsUrl(record.googleDocId)} target="_blank">
                      Print Invoice
                    </Button>
                    <Link to={`/doctor/invoice/${record.id}/payment`} target="_blank">
                      <Button>Payments Page</Button>
                    </Link>
                  </>
                );
              }}
            />
          </Table>
        </div>
      );
    }}
  </Query>
);

InvoiceReport.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default InvoiceReport;
