import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './CalendarMonthButton.scss';

const months = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

const CalendarMonthButton = (props) => {
  const { selected, year, month } = props;
  return (
    <div
      role="button"
      onKeyPress={null}
      tabIndex="0"
      className={
        selected
          ? 'calendar-month-btn calendar-month-btn-selected'
          : 'calendar-month-btn calendar-month-btn-not-selected'
      }
      onClick={() => {
        if (selected) return;
        const newDate = moment({
          month,
          year,
        });

        if (!props.allowBackDates && newDate.isBefore(moment(), 'day')) {
          props.clickHandler(moment());
        } else {
          props.clickHandler(newDate);
        }
      }}
    >
      <p className="cmb-month">{months[month]}</p>
      <p className="cmb-year">{year}</p>
    </div>
  );
};

CalendarMonthButton.propTypes = {
  month: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]).isRequired,
  year: PropTypes.number.isRequired,
  clickHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  allowBackDates: PropTypes.bool,
};

CalendarMonthButton.defaultProps = {
  allowBackDates: false,
  selected: false,
};

export default CalendarMonthButton;
