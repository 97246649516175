import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { Mutation } from 'react-apollo';
import { Button } from 'antd';
import { getCurrency } from '../utils/globals';

import razorpayPaymentReminderMutation from '../graphql/mutations/razorpayPaymentReminderMutation.graphql';
import cancelRazorpayPaymentLinkMutation from '../graphql/mutations/cancelRazorpayPaymentLinkMutation.graphql';

const RazorpayPaymentLinkComponent = ({ razorpayPaymentLink, refetchQuery }) => {
  let lastReminderSmsTimestamp = null;
  if (Array.isArray(razorpayPaymentLink.reminderSmsLogs) && razorpayPaymentLink.reminderSmsLogs.length > 0) {
    razorpayPaymentLink.reminderSmsLogs.forEach((entry) => {
      if (!lastReminderSmsTimestamp || (lastReminderSmsTimestamp && entry.timestamp > lastReminderSmsTimestamp))
        lastReminderSmsTimestamp = entry.timestamp;
    });
  }

  if (razorpayPaymentLink.status === 'paid') {
    return (
      <div style={{ padding: '10px 0', borderBottom: '1px solid #DDDDDD' }}>
        <p>
          <strong>Reference #: </strong>
          {razorpayPaymentLink.razorpayId}
        </p>
        <p>
          <strong>Amount: </strong>
          {getCurrency(razorpayPaymentLink.amount)}
        </p>
        <p style={{ fontWeight: 700, fontSize: '0.95rem' }}>Payment done!</p>
      </div>
    );
  }

  return (
    <div style={{ padding: '10px 0', borderBottom: '1px solid #DDDDDD' }}>
      <p>
        <strong>Reference #: </strong>
        {razorpayPaymentLink.razorpayId}
      </p>
      <p>
        <strong>Amount: </strong>
        {getCurrency(razorpayPaymentLink.amount)}
      </p>
      {lastReminderSmsTimestamp ? (
        <p>
          Last reminder SMS:
          <br />
          <strong> {moment(new Date(lastReminderSmsTimestamp)).format('Do MMM [at] LT')}</strong>
        </p>
      ) : (
        <p>No reminders sent!</p>
      )}
      <Mutation
        mutation={razorpayPaymentReminderMutation}
        variables={{ razorpayPaymentLinkId: razorpayPaymentLink.id }}
        onCompleted={() => {
          refetchQuery();
        }}
        onError={(mutErr) => {
          Sentry.captureException(mutErr);
        }}
      >
        {(razorpayPaymentReminder, { loading }) => (
          <Button type="primary" loading={loading} onClick={razorpayPaymentReminder} style={{ marginTop: 10 }}>
            Send Razorpay Reminder SMS
          </Button>
        )}
      </Mutation>
      <Mutation
        mutation={cancelRazorpayPaymentLinkMutation}
        variables={{ razorpayPaymentLinkId: razorpayPaymentLink.id }}
        onCompleted={() => {
          refetchQuery();
        }}
        onError={(mutErr) => {
          Sentry.captureException(mutErr);
        }}
      >
        {(cancelRazorpayPaymentLink, { loading }) => (
          <Button
            type="primary"
            loading={loading}
            onClick={cancelRazorpayPaymentLink}
            style={{ marginTop: 10, display: 'block' }}
          >
            Cancel Payment Link
          </Button>
        )}
      </Mutation>
    </div>
  );
};

RazorpayPaymentLinkComponent.propTypes = {
  razorpayPaymentLink: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reminderSmsLogs: PropTypes.array.isRequired,
    status: PropTypes.string.isRequired,
    razorpayId: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  refetchQuery: PropTypes.func.isRequired,
};

export default RazorpayPaymentLinkComponent;
