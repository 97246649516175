import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin, Button, Table, Input, Icon } from 'antd';
import moment from 'moment';
import Link from './Link';
import { getCurrency, getGoogleDocsUrl } from '../utils/globals';

import amountDueReportQuery from '../graphql/queries/amountDueReportQuery.graphql';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, name }) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => {
  return {
    filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      let data;
      if (name === 'invoice no.') {
        data = record[dataIndex];
      } else {
        data = record.patient[dataIndex];
      }

      return data
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
  };
};

const AmountDueReport = ({ startDate, endDate }) => (
  <Query
    query={amountDueReportQuery}
    variables={{ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin />;
      if (error) return <p>ERROR: {error.message}</p>;
      const { amountDueReport } = data;
      let totalDue = 0;
      amountDueReport.forEach((entry) => {
        totalDue += entry.summary.balance + entry.summary.totalRazorpayIssued;
      });

      return (
        <div>
          <h2 style={{ marginBottom: 25 }}>
            Amount Due report from <strong>{startDate.format('Do MMMM, YYYY')}</strong> to{' '}
            <strong>{endDate.format('Do MMMM, YYYY')}</strong>
          </h2>
          <h3 style={{ marginBottom: 30 }}>
            Total Amount Due = <strong>{getCurrency(totalDue)}</strong>
          </h3>
          <Table loading={loading} dataSource={amountDueReport} rowKey="id" pagination={false} bordered>
            <Column
              title={<strong>Invoice Date</strong>}
              key="date"
              render={(text, record) => moment(record.date, 'YYYY-MM-DD').format('Do MMM, YYYY')}
            />
            <Column
              title={<strong>Invoice No.</strong>}
              key="number"
              dataIndex="number"
              {...getColumnSearchProps('number', 'invoice no.')}
            />
            <Column
              title={<strong>Patient</strong>}
              key="patient"
              dataIndex="patient"
              render={(text, record) => (
                <>
                  {record.patient.name} <br /> +91-{record.patient.mobile}
                </>
              )}
              {...getColumnSearchProps('name', 'name')}
            />
            <Column
              title={<strong>Invoice Total</strong>}
              key="summary.invoiceTotal"
              dataIndex="summary.invoiceTotal"
              render={(text, record) => {
                return <span style={{ float: 'right' }}>{getCurrency(record.summary.invoiceTotal)}</span>;
              }}
            />
            <Column
              title={<strong>Amount Due</strong>}
              key="summary.balance"
              dataIndex="summary.balance"
              render={(text, record) => {
                return (
                  <span style={{ float: 'right' }}>
                    {getCurrency(record.summary.balance + record.summary.totalRazorpayIssued)}
                  </span>
                );
              }}
            />
            <Column
              title={<strong>Actions</strong>}
              key="actions"
              dataIndex="actions"
              className="hideInPrint"
              render={(text, record) => {
                return (
                  <>
                    <Button href={getGoogleDocsUrl(record.googleDocId)} target="_blank">
                      Print Invoice
                    </Button>
                    <Link to={`/doctor/invoice/${record.id}/payment`} target="_blank">
                      <Button>Payments Page</Button>
                    </Link>
                  </>
                );
              }}
            />
          </Table>
        </div>
      );
    }}
  </Query>
);

AmountDueReport.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default AmountDueReport;
