import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { useQuery, useMutation } from 'react-apollo';
import { Form, Input, Button, Spin, Radio, Col, Row, message } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import Paper from '@material-ui/core/Paper';
import patientQuery from '../graphql/queries/patientQuery.graphql';
import savePatientBasicDetailsMutation from '../graphql/mutations/savePatientBasicDetailsMutation.graphql';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const UpdatePatientBasicDetails = (props) => {
  const { form, match, history } = props;
  const { getFieldDecorator } = form;

  let { patientId } = match.params;
  patientId = parseInt(patientId, 10);

  const [submittingForm, setSubmittingForm] = useState(false);
  const { loading, error, data } = useQuery(patientQuery, {
    variables: { id: patientId },
  });
  const [updatePatientDetails] = useMutation(savePatientBasicDetailsMutation);

  if (loading) return <Spin />;
  if (error) return `Error! ${error.message}`;
  const { patient } = data;
  const initialData = {
    firstName: patient.firstName,
    middleName: patient.middleName,
    lastName: patient.lastName,
    mobile: patient.mobile,
    email: patient.email,
    sex: patient.sex,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmittingForm(true);
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const patientBasicData = {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          mobile: values.mobile,
          email: values.email,
          sex: values.sex,
        };
        updatePatientDetails({
          variables: { ...patientBasicData, ...{ patientId } },
          update: (cache) => {
            cache.writeQuery({
              query: patientQuery,
              data: { patient: { ...patientBasicData, ...{ id: patientId, __typename: 'Patient' } } },
            });
          },
        })
          .then(() => {
            setSubmittingForm(false);
            form.resetFields();
            message.success('Success!');
            history.goBack();
          })
          .catch((mutateErr) => {
            setSubmittingForm(false);
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        setSubmittingForm(false);
      }
    });
  };

  return (
    <div style={{ padding: 20 }}>
      <Row>
        <Col span={24}>
          <Paper style={{ padding: 30 }}>
            <h1 style={{ textAlign: 'center' }}>Patient Basic Details</h1>
            <Form onSubmit={handleSubmit} layout="horizontal">
              <FormItem hasFeedback label="First Name" {...formItemLayout}>
                {getFieldDecorator('firstName', {
                  initialValue: initialData.firstName,
                  rules: [
                    {
                      required: true,
                      message: 'First name is required!',
                    },
                    {
                      pattern: validationRegex.charactersOnly,
                      message: 'Incorrect name!',
                    },
                  ],
                })(<Input type="text" required disabled={submittingForm} />)}
              </FormItem>
              <FormItem hasFeedback label="Middle Name" {...formItemLayout}>
                {getFieldDecorator('middleName', {
                  initialValue: initialData.middleName,
                  rules: [
                    {
                      pattern: validationRegex.charactersOnly,
                      message: 'Incorrect name!',
                    },
                  ],
                })(<Input type="text" disabled={submittingForm} />)}
              </FormItem>
              <FormItem hasFeedback label="Last Name" {...formItemLayout}>
                {getFieldDecorator('lastName', {
                  initialValue: initialData.lastName,
                  rules: [
                    {
                      required: true,
                      message: 'Last name is required!',
                    },
                    {
                      pattern: validationRegex.charactersOnly,
                      message: 'Incorrect name!',
                    },
                  ],
                })(<Input type="text" required disabled={submittingForm} />)}
              </FormItem>
              <FormItem hasFeedback label="Mobile" {...formItemLayout}>
                {getFieldDecorator('mobile', {
                  initialValue: initialData.mobile,
                  rules: [
                    {
                      required: true,
                      message: 'Mobile is required!',
                    },
                    {
                      pattern: validationRegex.mobile,
                      message: 'Incorrect mobile no!',
                    },
                  ],
                })(<Input type="text" required disabled={submittingForm} />)}
              </FormItem>
              <FormItem hasFeedback label="Email" {...formItemLayout}>
                {getFieldDecorator('email', {
                  initialValue: initialData.email ? initialData.email : null,
                  rules: [
                    {
                      type: 'email',
                      message: 'Incorrect value entered!',
                    },
                  ],
                })(<Input type="email" disabled={submittingForm} />)}
              </FormItem>
              <FormItem label="Sex" {...formItemLayout}>
                {getFieldDecorator('sex', {
                  initialValue: initialData.sex ? initialData.sex : 'M',
                  rules: [
                    {
                      required: true,
                      message: 'Field is required!',
                    },
                  ],
                })(
                  <RadioGroup disabled={submittingForm}>
                    <Radio value="M">M</Radio>
                    <Radio value="F">F</Radio>
                  </RadioGroup>,
                )}
              </FormItem>
              <FormItem wrapperCol={{ span: 12, offset: 5 }}>
                <Button type="primary" htmlType="submit" loading={submittingForm}>
                  Submit
                </Button>
              </FormItem>
            </Form>
          </Paper>
        </Col>
      </Row>
    </div>
  );
};

UpdatePatientBasicDetails.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    isFieldTouched: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create({ name: 'UpdatePatientBasicDetails' })(UpdatePatientBasicDetails);
