import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import InvoicePaymentComponent from '../components/InvoicePaymentComponent';

const InvoicePaymentPage = ({ match }) => (
  <div style={{ padding: 20 }}>
    <Paper style={{ padding: 20 }}>
      <InvoicePaymentComponent invoiceId={parseInt(match.params.invoiceId, 10)} />
    </Paper>
  </div>
);

InvoicePaymentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      invoiceId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default InvoicePaymentPage;
