import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql, Mutation, withApollo } from 'react-apollo';
import * as compose from 'lodash/flowRight';
import localforage from 'localforage';
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Select,
  Spin,
  Row,
  Col,
  Checkbox,
  TimePicker,
  Radio,
  AutoComplete,
  DatePicker,
  Popconfirm,
  Divider,
} from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import moment from 'moment';
import Link from '../components/Link';
import { medicalProblems } from '../utils/globals';
import './DietPlanForm.scss';

import mealsSearchQuery from '../graphql/queries/mealsSearchQuery.graphql';
import QUERY from '../graphql/queries/dietPlanSettingsQuery.graphql';
import SaveDietPlanMutation from '../graphql/mutations/saveDietPlanMutation.graphql';
import sendDietPlanModifiedSmsEmailMutation from '../graphql/mutations/sendDietPlanModifiedSmsEmailMutation.graphql';

const FormItem = Form.Item;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const timeMomentFormat = 'HH:mm';

class DietPlanForm extends Component {
  state = {
    submittingForm: false,
    id: null,
    mode: 'view',
    entriesKeys: [],
    entriesNextKey: 0,
    generalGuidelinesKeys: [],
    generalGuidelinesNextKey: 0,
    specialGuidelinesKeys: [],
    specialGuidelinesNextKey: 0,
    locallySavedDpExists: false,
    // state to set the loading for individual select based on its key
    mealsSearchLoadingKey: null,
    // state to set the filtered data based on users search
    filteredMealOptions: [],
  };

  componentDidMount() {
    const { patientId } = this.props;
    if (Number.isInteger(patientId)) {
      localforage
        .getItem(`dietPlan_pat-${patientId}`)
        .then((value) => {
          if (value) {
            this.setState({ locallySavedDpExists: true });
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  }

  formatValuesToSubmit = (valuesToSubmit, woTime) => {
    const { entriesKeys, generalGuidelinesKeys, specialGuidelinesKeys } = this.state;
    valuesToSubmit.entries = entriesKeys.map((key1) => valuesToSubmit.entries[key1]);
    valuesToSubmit.generalGuidelines = generalGuidelinesKeys.map((key1) => valuesToSubmit.generalGuidelines[key1]);
    valuesToSubmit.specialGuidelines = specialGuidelinesKeys.map((key1) => valuesToSubmit.specialGuidelines[key1]);

    if (valuesToSubmit.startDate) {
      valuesToSubmit.startDate = valuesToSubmit.startDate.format('YYYY-MM-DD');
    }
    if (valuesToSubmit.endDate) {
      valuesToSubmit.endDate = valuesToSubmit.endDate.format('YYYY-MM-DD');
    }

    if (
      valuesToSubmit.medicalProblems &&
      Array.isArray(valuesToSubmit.medicalProblems) &&
      valuesToSubmit.medicalProblems.length > 0
    ) {
      valuesToSubmit.medicalProblems = valuesToSubmit.medicalProblems.map((value) => parseInt(value, 10));
    }
    if (woTime) {
      valuesToSubmit.woTimeHours = woTime.hours();
      valuesToSubmit.woTimeMinutes = woTime.minutes();
    }
    valuesToSubmit.entries = valuesToSubmit.entries.map((entry) => {
      const { time, meals, supplements, ...rest } = entry;
      return {
        hours: time.hours(),
        minutes: time.minutes(),
        meals: meals.filter((entry1) => Array.isArray(entry1) && entry1.length > 0),
        supplements: supplements.filter((entry2) => !!entry2),
        ...rest,
      };
    });
    return valuesToSubmit;
  };

  addDietEntry = () => {
    const { entriesKeys, entriesNextKey } = this.state;
    this.setState({
      entriesKeys: [...entriesKeys, entriesNextKey],
      entriesNextKey: entriesNextKey + 1,
    });
  };

  removeDietEntry = (key) => {
    const { entriesKeys } = this.state;
    this.setState({
      entriesKeys: entriesKeys.filter((entry) => entry !== key),
    });
  };

  addGeneralGuideline = () => {
    const { generalGuidelinesKeys, generalGuidelinesNextKey } = this.state;
    this.setState({
      generalGuidelinesKeys: [...generalGuidelinesKeys, generalGuidelinesNextKey],
      generalGuidelinesNextKey: generalGuidelinesNextKey + 1,
    });
  };

  removeGeneralGuideline = (key) => {
    const { generalGuidelinesKeys } = this.state;
    this.setState({
      generalGuidelinesKeys: generalGuidelinesKeys.filter((entry) => entry !== key),
    });
  };

  addSpecialGuideline = () => {
    const { specialGuidelinesKeys, specialGuidelinesNextKey } = this.state;
    this.setState({
      specialGuidelinesKeys: [...specialGuidelinesKeys, specialGuidelinesNextKey],
      specialGuidelinesNextKey: specialGuidelinesNextKey + 1,
    });
  };

  removeSpecialGuideline = (key) => {
    const { specialGuidelinesKeys } = this.state;
    this.setState({
      specialGuidelinesKeys: specialGuidelinesKeys.filter((entry) => entry !== key),
    });
  };

  getInitialDietEntryTime = (key) => {
    const { defaultValues } = this.props;
    let out = null;
    if (
      defaultValues &&
      Array.isArray(defaultValues.entries) &&
      defaultValues.entries.length > 0 &&
      key < defaultValues.entries.length
    ) {
      out = moment({ hours: defaultValues.entries[key].hours, minutes: defaultValues.entries[key].minutes });
    }
    return out;
  };

  getGeneralGuidelinesInitialValue = (key) => {
    const { defaultValues } = this.props;
    let out = null;
    if (
      defaultValues &&
      Array.isArray(defaultValues.generalGuidelines) &&
      defaultValues.generalGuidelines.length > 0 &&
      key < defaultValues.generalGuidelines.length
    ) {
      out = defaultValues.generalGuidelines[key];
    }
    return out;
  };

  getSpecialGuidelinesInitialValue = (key) => {
    const { defaultValues } = this.props;
    let out = null;
    if (
      defaultValues &&
      Array.isArray(defaultValues.specialGuidelines) &&
      defaultValues.specialGuidelines.length > 0 &&
      key < defaultValues.specialGuidelines.length
    ) {
      out = defaultValues.specialGuidelines[key];
    }
    return out;
  };

  handleSave = (mutate) => {
    this.setState({
      submittingForm: true,
    });

    const { mode } = this.state;
    const { form, patientId } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { woTime, ...remainingValues } = values;
        const valuesToSubmit = this.formatValuesToSubmit(remainingValues, woTime);
        mutate({ variables: { ...valuesToSubmit, create: !!(mode === 'create') } })
          .then(() => {
            // form.resetFields();
            this.setState({
              submittingForm: false,
              mode: 'view',
            });
            localforage.removeItem(`dietPlan_pat-${patientId}`).then(() => {
              this.setState({ locallySavedDpExists: false });
            });
            message.success('Diet plan saved');
            // refetchQuery();
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  savePlanLocally = () => {
    const { id, mode, submittingForm, locallySavedDpExists, ...restState } = this.state;
    const { form, patientId } = this.props;
    if (Number.isInteger(patientId)) {
      const formValues = form.getFieldsValue();
      const saveObj = { state: restState, formValues };

      localforage
        .setItem(`dietPlan_pat-${patientId}`, JSON.stringify(saveObj))
        .then(() => {
          message.success('Diet plan saved');
          this.setState({ locallySavedDpExists: true });
        })
        .catch((err) => {
          console.log(err);
          message.error(err.message, 3);
          Sentry.captureException(err);
        });
    } else {
      message.error('Cannot save as patient id was not provided', 3);
    }
  };

  loadLocallySavedDietPlan = () => {
    // Check if locally saved data exists
    const { form, patientId } = this.props;
    if (Number.isInteger(patientId)) {
      localforage
        .getItem(`dietPlan_pat-${patientId}`)
        .then((value) => {
          if (value) {
            const savedData = JSON.parse(value);
            if (savedData.formValues.woTime) savedData.formValues.woTime = moment(savedData.formValues.woTime);
            savedData.formValues.entries = savedData.formValues.entries.map((entry) => {
              if (entry && entry.time) return { ...entry, time: moment(entry.time) };
              return entry;
            });
            // Unset start date and end date
            const { startDate, endDate, ...valuesToUpdate } = savedData.formValues;
            this.setState(savedData.state, () => {
              form.setFieldsValue(valuesToUpdate);
            });
            message.success('Diet plan loaded!');
            message.info('Please re-check the start and end date. As they are not saved!', 5);
          } else {
            message.info('No saved diet plan found', 3);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  };

  sortMeals = () => {
    const { form } = this.props;
    const { entriesKeys } = this.state;
    const entries = form.getFieldValue('entries');
    const newentries = entriesKeys.sort((a, b) => entries[a].time.diff(entries[b].time));
    this.setState({
      entriesKeys: newentries,
    });
  };

  // function to fetch filtered data based on user's search
  fetchMealsBasedOnSearch = (value, key) => {
    const { client } = this.props;
    this.setState({ filteredMealOptions: [], mealsSearchLoadingKey: key });
    if (value.length >= 3) {
      client
        .query({
          query: mealsSearchQuery,
          variables: {
            searchText: value,
          },
        })
        .then((res) => {
          this.setState({ filteredMealOptions: res.data.mealsSearch, mealsSearchLoadingKey: null });
        })
        .catch((err) => {
          this.setState({ mealsSearchLoadingKey: null });
          console.log('error', err);
        });
    }
  };

  render() {
    const {
      form,
      data,
      defaultValues,
      prevDietPlanId,
      appointmentId,
      isLastDietPlan,
      defaultValidityDays,
      planStatus,
    } = this.props;
    if (data.loading) return <Spin />;
    const { supplementsSetting } = data;
    const { getFieldDecorator, getFieldValue } = form;
    const {
      submittingForm,
      entriesKeys,
      generalGuidelinesKeys,
      specialGuidelinesKeys,
      mode,
      id,
      locallySavedDpExists,
      mealsSearchLoadingKey,
      filteredMealOptions,
    } = this.state;

    let initialWoTime = null;
    if (defaultValues && Number.isInteger(defaultValues.woTimeHours) && Number.isInteger(defaultValues.woTimeMinutes)) {
      initialWoTime = moment({ hours: defaultValues.woTimeHours, minutes: defaultValues.woTimeMinutes });
    }

    let initialMedicalProblems = [];
    if (defaultValues && Array.isArray(defaultValues.medicalProblems) && defaultValues.medicalProblems.length > 0) {
      initialMedicalProblems = defaultValues.medicalProblems.map((entry) => `${entry}`);
    }

    let initialDietType = 'Vegetarian';
    if (defaultValues && defaultValues.dietType) {
      initialDietType = defaultValues.dietType;
    }

    let formDisabled = false;
    if (mode === 'view' || mode === 'print') formDisabled = true;

    getFieldDecorator('id', { initialValue: id });
    if (appointmentId) getFieldDecorator('appointmentId', { initialValue: appointmentId });
    getFieldDecorator('prevDietPlanId', { initialValue: prevDietPlanId });

    let showEndDateInput = false;
    if (isLastDietPlan && (mode === 'create' || mode === 'edit')) showEndDateInput = true;

    let startDateInitialValue = moment().add(1, 'days');
    if (defaultValues && defaultValues.startDate) startDateInitialValue = defaultValues.startDate;

    let endDateInitialValue = moment(startDateInitialValue)
      .add(defaultValidityDays - 1, 'days')
      .endOf('day');
    if (defaultValues && defaultValues.endDate) endDateInitialValue = moment(new Date(defaultValues.endDate));
    const startDateMoment = getFieldValue('startDate');
    if (startDateMoment) endDateInitialValue = moment(startDateMoment).add(defaultValidityDays - 1, 'days');

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <FormItem hasFeedback label="Weight (kg)" {...formItemLayout}>
            {getFieldDecorator('weight', {
              initialValue: defaultValues && defaultValues.weight ? defaultValues.weight : null,
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
                {
                  pattern: validationRegex.float,
                  message: 'Incorrect value!',
                },
              ],
            })(<InputNumber required disabled={submittingForm || formDisabled} />)}
          </FormItem>
          <FormItem label="Fitness Goals" {...formItemLayout}>
            {getFieldDecorator('fitnessGoals', {
              initialValue: defaultValues && defaultValues.fitnessGoals ? defaultValues.fitnessGoals : null,
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
              ],
            })(
              <CheckboxGroup disabled={submittingForm || formDisabled}>
                <Checkbox value="Fat Loss">Fat Loss</Checkbox>
                <Checkbox value="Muscle Gain">Muscle Gain</Checkbox>
                <Checkbox value="General Fitness">General Fitness</Checkbox>
                <Checkbox value="Athletic Fitness">Athletic Fitness</Checkbox>
              </CheckboxGroup>,
            )}
          </FormItem>
          <FormItem label="W/O Time" {...formItemLayout}>
            {getFieldDecorator('woTime', { initialValue: initialWoTime })(
              <TimePicker
                disabled={submittingForm || formDisabled}
                defaultOpenValue={moment('06:00', timeMomentFormat)}
                format="hh:mm A"
                minuteStep={15}
                use12Hours
              />,
            )}
          </FormItem>
          <FormItem label="Medical Problems" {...formItemLayout}>
            {getFieldDecorator('medicalProblems', {
              initialValue: initialMedicalProblems,
            })(
              <Select mode="tags" style={{ width: '100%' }} disabled={submittingForm || formDisabled}>
                {medicalProblems.map((entry) => (
                  <Option key={entry.id} value={`${entry.id}`}>
                    {entry.title}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
          <FormItem label="Diet Type" {...formItemLayout}>
            {getFieldDecorator('dietType', {
              initialValue: initialDietType,
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
              ],
            })(
              <RadioGroup disabled={submittingForm || formDisabled}>
                <Radio value="Vegetarian">Vegetarian</Radio>
                <Radio value="Eggitarian">Eggitarian</Radio>
                <Radio value="Non-Vegetarian">Non-Vegetarian</Radio>
              </RadioGroup>,
            )}
          </FormItem>
          <FormItem hasFeedback label="Water Intake (lit/day)" {...formItemLayout}>
            {getFieldDecorator('waterIntake', {
              initialValue: defaultValues && defaultValues.waterIntake ? defaultValues.waterIntake : 3,
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
              ],
            })(<InputNumber min={1} max={10} required disabled={submittingForm || formDisabled} />)}
          </FormItem>
          <FormItem hasFeedback label="Oil Intake (gm/day)" {...formItemLayout}>
            {getFieldDecorator('oilIntake', {
              initialValue: defaultValues && defaultValues.oilIntake ? defaultValues.oilIntake : 10,
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
              ],
            })(<InputNumber min={1} required disabled={submittingForm || formDisabled} />)}
          </FormItem>

          <h2>Meal Table</h2>
          <Button type="primary" onClick={this.sortMeals}>
            Sort meals
          </Button>
          <table id="dietPlanDietChartTable">
            <thead>
              <tr>
                <th style={{ width: '17%' }}>Time</th>
                <th style={{ width: '60%' }}>Meals</th>
                <th style={{ width: '20%' }}>Supplements</th>
                <th style={{ width: '3%' }} />
              </tr>
            </thead>
            <tbody>
              {entriesKeys.map((key) => (
                <tr key={key}>
                  <td>
                    <FormItem>
                      {getFieldDecorator(`entries[${key}].time`, {
                        initialValue: this.getInitialDietEntryTime(key),
                        rules: [
                          {
                            required: true,
                            message: 'Field is required!',
                          },
                        ],
                      })(
                        <TimePicker
                          disabled={submittingForm || formDisabled}
                          format="hh:mm A"
                          minuteStep={15}
                          use12Hours
                        />,
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator(`entries[${key}].mealType`, {
                        initialValue:
                          defaultValues && defaultValues.entries[key] && defaultValues.entries[key].mealType
                            ? defaultValues.entries[key].mealType
                            : null,
                      })(
                        <AutoComplete
                          disabled={submittingForm || formDisabled}
                          dataSource={[
                            'Early Morning',
                            'Pre W/O',
                            'Post W/O',
                            'Breakfast',
                            'Lunch',
                            'Dinner',
                            'Mid Meal',
                            'Snacks',
                            'Before Sleeping',
                          ]}
                          placeholder="Meal Type"
                          style={{ width: '100%' }}
                        />,
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator(`entries[${key}].timeNote`, {
                        initialValue:
                          defaultValues && defaultValues.entries[key] && defaultValues.entries[key].timeNote
                            ? defaultValues.entries[key].timeNote
                            : null,
                      })(
                        <Input
                          placeholder="Note"
                          style={{ width: '100%' }}
                          disabled={submittingForm || formDisabled}
                        />,
                      )}
                    </FormItem>
                  </td>
                  <td>
                    {[0, 1, 2, 3, 4].map((entry2) => (
                      <FormItem key={`${entry2}`} style={{ margin: 0 }}>
                        {getFieldDecorator(`entries[${key}].meals[${entry2}]`, {
                          initialValue:
                            defaultValues && defaultValues.entries[key] && defaultValues.entries[key].meals[entry2]
                              ? defaultValues.entries[key].meals[entry2]
                              : [],
                        })(
                          <Select
                            mode="tags"
                            style={{ width: '100%', minWidth: 300 }}
                            disabled={submittingForm || formDisabled}
                            notFoundContent={mealsSearchLoadingKey ? <Spin size="small" /> : null}
                            filterOption={false}
                            onSearch={(value) => this.fetchMealsBasedOnSearch(value, `${entry2} ${key}`)}
                            placeholder="Select"
                            loading={`${entry2} ${key}` === mealsSearchLoadingKey}
                            showArrow
                          >
                            {filteredMealOptions.length > 0
                              ? filteredMealOptions.map((d) => <Option key={d}>{d}</Option>)
                              : []}
                          </Select>,
                        )}
                      </FormItem>
                    ))}
                  </td>
                  <td>
                    {[0, 1, 2, 3, 4].map((entry2) => (
                      <FormItem key={`${entry2}`} style={{ margin: 0 }}>
                        {getFieldDecorator(`entries[${key}].supplements[${entry2}]`, {
                          initialValue:
                            defaultValues &&
                            defaultValues.entries[key] &&
                            defaultValues.entries[key].supplements[entry2]
                              ? defaultValues.entries[key].supplements[entry2]
                              : null,
                        })(
                          <AutoComplete
                            disabled={submittingForm || formDisabled}
                            dataSource={supplementsSetting.arrayStringValue}
                            style={{ width: '100%' }}
                          />,
                        )}
                      </FormItem>
                    ))}
                  </td>
                  <td>
                    {mode === 'create' || mode === 'edit' || mode === 'local' ? (
                      <Button
                        htmlType="button"
                        size="small"
                        type="danger"
                        onClick={() => this.removeDietEntry(key)}
                        disabled={submittingForm || formDisabled}
                        icon="close"
                        shape="circle"
                      />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {mode === 'create' || mode === 'edit' || mode === 'local' ? (
            <Button
              htmlType="button"
              onClick={this.addDietEntry}
              disabled={submittingForm || formDisabled}
              size="small"
              style={{ margin: '15px 0 20px' }}
            >
              Add New Meal Table Entry
            </Button>
          ) : null}
          <div>
            <Button type="primary" onClick={this.sortMeals}>
              Sort meals
            </Button>
          </div>

          <Divider />

          <h2>General Guidelines</h2>
          {generalGuidelinesKeys.map((key) => (
            <Row key={key} gutter={10} type="flex" align="middle" style={{ marginTop: 10 }}>
              <Col span={20}>
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(`generalGuidelines[${key}]`, {
                    initialValue: this.getGeneralGuidelinesInitialValue(key),
                    rules: [
                      {
                        required: true,
                        message: 'Field is required!',
                      },
                    ],
                  })(
                    <Input.TextArea
                      required
                      autosize={{ minRows: 1, maxRows: 3 }}
                      disabled={submittingForm || formDisabled}
                    />,
                  )}
                </FormItem>
              </Col>
              {mode === 'create' || mode === 'edit' || mode === 'local' ? (
                <Col span={4}>
                  <Button
                    type="danger"
                    icon="close"
                    shape="circle"
                    size="small"
                    disabled={submittingForm || formDisabled}
                    onClick={() => this.removeGeneralGuideline(key)}
                  />
                </Col>
              ) : null}
            </Row>
          ))}
          {mode === 'create' || mode === 'edit' || mode === 'local' ? (
            <Button
              htmlType="button"
              onClick={this.addGeneralGuideline}
              disabled={submittingForm || formDisabled}
              size="small"
              style={{ margin: '15px 0 20px' }}
            >
              Add General Guideline
            </Button>
          ) : null}

          <h2 style={{ marginTop: 20 }}>Special Guidelines</h2>
          {specialGuidelinesKeys.map((key) => (
            <Row key={key} gutter={10} type="flex" align="middle" style={{ marginTop: 10 }}>
              <Col span={20}>
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(`specialGuidelines[${key}]`, {
                    initialValue: this.getSpecialGuidelinesInitialValue(key),
                    rules: [
                      {
                        required: true,
                        message: 'Field is required!',
                      },
                    ],
                  })(
                    <Input.TextArea
                      required
                      autosize={{ minRows: 1, maxRows: 3 }}
                      disabled={submittingForm || formDisabled}
                    />,
                  )}
                </FormItem>
              </Col>
              {mode === 'create' || mode === 'edit' || mode === 'local' ? (
                <Col span={4}>
                  <Button
                    type="danger"
                    icon="close"
                    shape="circle"
                    size="small"
                    onClick={() => this.removeSpecialGuideline(key)}
                    disabled={submittingForm || formDisabled}
                  />
                </Col>
              ) : null}
            </Row>
          ))}
          {mode === 'create' || mode === 'edit' || mode === 'local' ? (
            <Button
              htmlType="button"
              onClick={this.addSpecialGuideline}
              disabled={submittingForm || formDisabled}
              size="small"
              style={{ margin: '15px 0 20px' }}
            >
              Add Special Guideline
            </Button>
          ) : null}

          {(mode === 'create' || mode === 'edit') && planStatus !== 'on' ? (
            <div>
              <FormItem hasFeedback label="Diet plan start date" {...formItemLayout}>
                {getFieldDecorator('startDate', {
                  initialValue: startDateInitialValue,
                  rules: [
                    {
                      required: true,
                      message: 'Field is required!',
                    },
                  ],
                })(<DatePicker disabled={submittingForm || formDisabled} format="Do MMM, YYYY" />)}
              </FormItem>
            </div>
          ) : null}

          {showEndDateInput ? (
            <div>
              <FormItem hasFeedback label="Diet plan end date" {...formItemLayout}>
                {getFieldDecorator('endDate', {
                  initialValue: endDateInitialValue,
                  rules: [
                    {
                      required: true,
                      message: 'Field is required!',
                    },
                  ],
                })(<DatePicker disabled={submittingForm || formDisabled} format="Do MMM, YYYY" />)}
              </FormItem>
            </div>
          ) : null}

          <FormItem style={{ marginTop: 25 }}>
            {mode === 'create' || mode === 'edit' ? (
              <Mutation mutation={SaveDietPlanMutation}>
                {(saveDietPlan) => (
                  <Button
                    type="primary"
                    htmlType="button"
                    loading={submittingForm}
                    size="large"
                    onClick={() => {
                      this.handleSave(saveDietPlan);
                    }}
                  >
                    {mode === 'create' ? 'Create Diet Plan' : 'Save Diet Plan'}
                  </Button>
                )}
              </Mutation>
            ) : null}

            {mode === 'view' ? (
              <Fragment>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => {
                    this.setState({ mode: 'edit' });
                  }}
                >
                  Edit Diet Plan
                </Button>
                <Mutation
                  mutation={sendDietPlanModifiedSmsEmailMutation}
                  variables={{ dietPlanId: id }}
                  onCompleted={() => {
                    message.success('SMS & Email sent!');
                  }}
                  onError={(mutError) => {
                    Sentry.captureException(mutError);
                  }}
                >
                  {(sendDietPlanModifiedSmsEmail, { loading: mutLoading }) => (
                    <Button
                      type="primary"
                      htmlType="button"
                      style={{ marginLeft: 15 }}
                      loading={mutLoading}
                      onClick={sendDietPlanModifiedSmsEmail}
                    >
                      Send DP modified SMS & Email
                    </Button>
                  )}
                </Mutation>
              </Fragment>
            ) : null}

            {mode === 'view' || mode === 'print' ? (
              <Link to={`/print/dietplan/${id}`} target="_blank">
                <Button type="primary" htmlType="button" style={{ marginLeft: 15 }}>
                  Print Diet Plan
                </Button>
              </Link>
            ) : null}

            {/* Save local plan */}
            {mode === 'local' ? (
              <Button type="primary" htmlType="button" onClick={this.savePlanLocally}>
                Save Diet Plan
              </Button>
            ) : null}
          </FormItem>
        </Form>
        <div
          style={{
            height: 50,
            padding: 10,
            position: 'fixed',
            right: 0,
            bottom: 0,
            zIndex: 10000,
            display: 'flex',
          }}
        >
          {locallySavedDpExists ? (
            <Popconfirm
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <span>
                  Previously saved diet plan was found!
                  <br />
                  Are you sure you want save new plan?
                  <br />
                  <span style={{ color: 'red', fontWeight: 'bold' }}>WILL OVERWRITE SAVED PLAN</span>
                </span>
              }
              onConfirm={this.savePlanLocally}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" htmlType="button" style={{ marginRight: 5 }}>
                Save Diet Plan
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" htmlType="button" onClick={this.savePlanLocally} style={{ marginRight: 5 }}>
              Save Diet Plan
            </Button>
          )}

          <Button
            type="primary"
            htmlType="button"
            onClick={this.loadLocallySavedDietPlan}
            disabled={!locallySavedDpExists}
          >
            Load Saved Diet Plan
          </Button>
        </div>
      </Fragment>
    );
  }
}

DietPlanForm.getDerivedStateFromProps = (props, state) => {
  const { defaultValues, mode, id } = props;
  if (id !== state.id) {
    const newState = { state };
    newState.mode = mode;
    newState.id = id;
    if (defaultValues) {
      if (Array.isArray(defaultValues.entries)) {
        newState.entriesKeys = defaultValues.entries.map((entry, index) => index);
        newState.entriesNextKey = defaultValues.entries.length;
      }
      if (Array.isArray(defaultValues.generalGuidelines) && defaultValues.generalGuidelines.length > 0) {
        newState.generalGuidelinesKeys = defaultValues.generalGuidelines.map((entry, index) => index);
        newState.generalGuidelinesNextKey = defaultValues.generalGuidelines.length;
      }
      if (Array.isArray(defaultValues.specialGuidelines) && defaultValues.specialGuidelines.length > 0) {
        newState.specialGuidelinesKeys = defaultValues.specialGuidelines.map((entry, index) => index);
        newState.specialGuidelinesNextKey = defaultValues.specialGuidelines.length;
      }
    }
    return newState;
  }
  return null;
};

DietPlanForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
  }).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    mealsSetting: PropTypes.shape({
      id: PropTypes.number.isRequired,
      arrayStringValue: PropTypes.array.isRequired,
    }),
    supplementsSetting: PropTypes.shape({
      id: PropTypes.number.isRequired,
      arrayStringValue: PropTypes.array.isRequired,
    }),
  }).isRequired,
  id: PropTypes.number.isRequired,
  appointmentId: PropTypes.number,
  mode: PropTypes.oneOf(['create', 'view', 'edit', 'print', 'local']),
  defaultValues: PropTypes.shape({
    weight: PropTypes.number,
    entries: PropTypes.array.isRequired,
    specialGuidelines: PropTypes.array.isRequired,
    generalGuidelines: PropTypes.array.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    woTimeHours: PropTypes.string.isRequired,
    woTimeMinutes: PropTypes.string.isRequired,
    medicalProblems: PropTypes.array.isRequired,
    dietType: PropTypes.string.isRequired,
    waterIntake: PropTypes.number.isRequired,
    oilIntake: PropTypes.number.isRequired,
    fitnessGoals: PropTypes.array.isRequired,
  }),
  refetchQuery: PropTypes.func,
  isLastDietPlan: PropTypes.bool,
  prevDietPlanId: PropTypes.number,
  defaultValidityDays: PropTypes.number,
  patientId: PropTypes.number.isRequired,
  planStatus: PropTypes.string,
  client: PropTypes.func.isRequired,
};

DietPlanForm.defaultProps = {
  appointmentId: null,
  mode: 'view',
  defaultValues: null,
  isLastDietPlan: false,
  prevDietPlanId: null,
  refetchQuery: null,
  defaultValidityDays: 7,
  planStatus: null,
};

export default compose(
  graphql(QUERY, { options: { fetchPolicy: 'network-only' } }),
  withApollo,
  Form.create(),
)(DietPlanForm);
