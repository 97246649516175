import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Button, Spin, Row, Col } from 'antd';
import { IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { getCurrency, getGoogleDocsUrl } from '../utils/globals';
import PaymentReceiptForm from '../forms/PaymentReceiptForm';
import RazorpayPaymentLinkComponent from './RazorpayPaymentLinkComponent';
import CreateRazorpayPaymentLinkForm from './CreateRazorpayPaymentLinkForm';

import invoicePaymentDetailsQuery from '../graphql/queries/invoicePaymentDetailsQuery.graphql';

const InvoicePaymentComponent = ({ invoiceId }) => (
  <Query
    variables={{ invoiceId }}
    query={invoicePaymentDetailsQuery}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data, refetch }) => {
      if (loading) return <Spin />;
      if (error) return `ERROR: ${error.message}`;

      const { invoice } = data;
      const { paymentReceipts, razorpayPaymentLinks, summary } = invoice;
      const hasPaymentReceipts = Array.isArray(paymentReceipts) && paymentReceipts.length > 0;
      const hasPaymentLinks = Array.isArray(razorpayPaymentLinks) && razorpayPaymentLinks.length > 0;

      let { balance } = summary;
      const { patient } = invoice;
      if (invoice.cancelled) balance = 0;

      return (
        <div>
          <h2>
            Invoice: {invoice.number}{' '}
            <IconButton
              color="primary"
              aria-label="Refresh"
              onClick={() => {
                refetch();
              }}
            >
              <Refresh />
            </IconButton>
          </h2>
          <p>
            <strong>Name:</strong> {patient && patient.name}
          </p>
          <p>
            <strong>Email:</strong> {patient && patient.email}
          </p>
          <p>
            <strong>Mobile:</strong> {patient && patient.mobile}
          </p>
          <p style={{ color: 'red' }}>
            Outstanding amount = <strong>{getCurrency(balance)}</strong>
          </p>
          <div style={{ padding: '15px 0', borderBottom: '1px solid #DDDDDD' }}>
            <Button href={getGoogleDocsUrl(invoice.googleDocId)} target="_blank" type="primary">
              Print Invoice
            </Button>
          </div>

          {hasPaymentReceipts ? (
            <div style={{ padding: '15px 0', borderBottom: '1px solid #DDDDDD' }}>
              <h3 style={{ fontWeight: 700 }}>Print Payment Receipts</h3>
              <div>
                {paymentReceipts.map((entry) => (
                  <Button
                    key={entry.id}
                    href={getGoogleDocsUrl(entry.googleDocId)}
                    target="_blank"
                    size="small"
                    type="primary"
                    style={{ marginRight: 10 }}
                  >
                    {entry.number}
                  </Button>
                ))}
              </div>
            </div>
          ) : null}

          {balance > 0 ? (
            <div style={{ padding: '15px 0', borderBottom: '1px solid #DDDDDD' }}>
              <Row gutter={15}>
                <Col span={12}>
                  <h3 style={{ fontWeight: 700 }}>New Razorpay Payment Link</h3>
                  <CreateRazorpayPaymentLinkForm invoiceId={invoiceId} style={{ marginLeft: 15 }} refetch={refetch} />
                </Col>
                <Col span={12}>
                  <h3 style={{ fontWeight: 700 }}>New Payment Receipt</h3>
                  <PaymentReceiptForm invoiceId={invoice.id} refetchQuery={refetch} />
                </Col>
              </Row>
            </div>
          ) : null}

          {hasPaymentLinks ? (
            <div style={{ padding: '15px 0' }}>
              <h3 style={{ fontWeight: 700 }}>Awaiting Razorpay Payment</h3>
              {razorpayPaymentLinks.map((entry) => (
                <RazorpayPaymentLinkComponent key={entry.id} razorpayPaymentLink={entry} refetchQuery={refetch} />
              ))}
            </div>
          ) : null}
        </div>
      );
    }}
  </Query>
);

InvoicePaymentComponent.propTypes = {
  invoiceId: PropTypes.number.isRequired,
};

export default InvoicePaymentComponent;
